import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import classes from './index.module.css';

function NavMenuTemplate(props) {
  const { signInLink, desktopLogo, mobileLogo } = props;
  const pathname = window.location.pathname.replace('/', '');

  return (
    <nav
      className={classes.navContainer}
      style={{
        background: 'rgba(0, 0, 0, 0)',
      }}
    >
      <Grid
        className={classes.navFullHeight}
        container
      >
        <Grid
          item
          xs={0}
          md={2}
        />
        <Grid
          className={clsx(classes.navFullHeight, classes.navLogoContainer)}
          item
          xs={12}
          md={3}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            className={clsx(classes.navLinkLogo, classes.logoDesktop)}
          >
            <Link
              to="/"
            >
              <img
                className={clsx(classes.logo, classes.desktopPadding)}
                src={desktopLogo}
                alt=""
              />
            </Link>
          </Box>
          <Box
            className={clsx(classes.navLinkLogo, classes.logoMobile)}
          >
            <Link
              to="/"
            >
              <img
                className={classes.logo}
                src={mobileLogo}
                alt=""
              />
            </Link>
          </Box>
        </Grid>
        <Grid
          item
          xs={2}
          md={2}
        />
        <Grid
          item
          className={clsx(classes.hideMobileGridItem, classes.hideSMGridItem)}
          md={3}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <a
            href="https://instagram.com/truplaygames?igshid=NTdlMDg3MTY="
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.navLogoIcon}
              src="/icons/instagram-skyblue-icon.svg"
              alt="Instagram Small Logo"
            />
          </a>
          <a
            href="https://www.facebook.com/truplaygames/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.navLogoIcon}
              src="/icons/facebook-skyblue-icon.svg"
              alt="Facebook Small Logo"
            />
          </a>
          <a
            href="https://twitter.com/TruplayGames/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.navLogoIcon}
              src="/icons/twitter-skyblue-icon.svg"
              alt="Twitter Small Logo"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/truplay/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.navLogoIcon}
              src="/icons/linkedin-skyblue-icon.svg"
              alt="LinkedIn Small Logo"
            />
          </a>
        </Grid>
        <Grid
          item
          xs={0}
          md={2}
        />
      </Grid>
    </nav>
  );
}

NavMenuTemplate.propTypes = {};

export default NavMenuTemplate;
