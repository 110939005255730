import React, { useEffect } from 'react'

const useScriptGA = () => {
  useEffect(()=> {
    // This script is being appended after page load
    
    // <!-- Google tag (gtag.js) -->
    // <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11041477554"></script>
    // <script>
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag(){dataLayer.push(arguments);}
    //   gtag('js', new Date());
    //   gtag('config', 'AW-11041477554');
    // </script>
    const script1 = document.createElement('script');
    script1.async = true;
    script1.src = "https://www.googletagmanager.com/gtag/js?id=G-T4KVLV2RNG";

    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      
      gtag('config', 'AW-11041477554'); 
    `;
    // console.log({ script1 });
    // console.log({ script2 });

    document.head.append(script1);
    document.head.append(script2);
  }, []);
}

export default useScriptGA;
