const data = {
  desktopLogo: '/images/truplay-logo.png',
  mobileLogo: '/images/truplay-logo.png',
  signInLink: '/getstarted',
  contentHeroImg: '/images/hero-background.png',
  contentHeroTitle1: 'Introducing',
  contentHeroTitle2: 'TruPlay Careers',
  contentHeroDescription: 'TruPlay Games has created a career\n advancement portal, powered by GoGig,\n designed for the more experienced professional.',
  contentHeroButtonLink: '/getstarted',
  contentHeroButtonText: 'Get Started',
  contentHeroBelowButtonText: '',
  contentHeroButtonSupport: 'Get Started',
  contentBody: [
    {
      avatarImg: '/images/avatar1.jpeg',
      avatarPosition: 20,
      body: [
        {
          text: 'MyCarePath Support is here to help. Learn how to optimize your experience and find resources that will help you throughout your CarePro journey.',
          type: 'text',
        },
        {
          type: 'linebreak',
        },
        {
          text: 'For the best MyCarePath experience, we recommend utilizing Google Chrome as your default browser.',
          type: 'text',
        },
        {
          type: 'linebreak',
        },
        {
          text: 'If experiencing issues while using MyCarePath, here are some tips and tricks: ',
          type: 'bold',
        },
        {
          type: 'linebreak',
        },
        {
          text: 'Recommended Tools:',
          type: 'bold',
        },
        {
          type: 'linebreak',
        },
        {
          text: '1. Clear your Cache',
          type: 'text',
        },
        {
          text: '2. Removing Cookies: ',
          type: 'text',
        },
        {
          text: '1. Click the Lock in your search bar next to the URL',
          type: 'spacedtext',
        },
        {
          text: '2. Click Cookies',
          type: 'spacedtext',
        },
        {
          text: '3. Choose mycarepath.org',
          type: 'spacedtext',
        },
        {
          text: '4. Click Remove',
          type: 'spacedtext',
        },
        {
          text: '5. Click Done',
          type: 'spacedtext',
        },
        {
          text: '6. Refresh your browser',
          type: 'spacedtext',
        },
        {
          text: '3. Reach out to Support: ',
          type: 'text',
        },
        {
          text: '1. Click on the Feedback icon in the bottom right corner of this page',
          type: 'spacedtext',
        },
        {
          text: '2. Type your name, email, and question into the text box',
          type: 'spacedtext',
        },
        {
          text: '3. Click Send',
          type: 'spacedtext',
        },
        {
          text: '4. Someone from Support will respond to you soon',
          type: 'spacedtext',
        },
      ],
    },
    {
      reverse: true,
      avatarImg: '/images/avatar2.jpeg',
      avatarPosition: 70,
      body: [
        {
          text: 'WhiteList Information:',
          type: 'bold',
        },
        {
          type: 'linebreak',
        },
        {
          text: 'It’s common for organizations to have strict protocols in place to safeguard resources from any external intruders. The MyCarePath.org, Powered by GoGig, team has outlined the necessary steps to ensure your IT Team has instruction to help you successfully connect with the platform. Please share this with your IT Team who will add these IP addresses/domain names to your firewall or VPNs allowlist.',
          type: 'text',
        },
        {
          type: 'linebreak',
        },
        {
          text: 'MyCarePath IP Address: 52.23.170.216',
          type: 'text',
        },
        {
          type: 'linebreak',
        },
        {
          text: 'Domains: mycarepath.org, mycarepath.org/info, mycarepath.org/support',
          type: 'text',
        },
      ],
    },
  ],
  contentBodyButtonText: 'Get Started',
  contentBodyButtonLink: '/getstarted',
  recentlyAdded: [
    '/avatars/female-2.jpg',
    '/avatars/male-1.jpg',
    '/avatars/male-14.jpg',
    '/avatars/female-4.jpg',
    '/avatars/female-7.jpg',
    '/avatars/male-2.jpg',
    '/avatars/female-10.jpg',
    '/avatars/male-12.jpg',
    '/avatars/female-5.jpg',
    '/avatars/female-12.jpg',
    '/avatars/male-6.jpg',
    '/avatars/female-14.jpg',
    '/avatars/male-7.jpg',
    '/avatars/male-10.jpg',
    '/avatars/male-11.jpg',
    '/avatars/female-11.jpg',
  ],
  footerLinks: [
    {
      icon: 'email',
      text: 'support@gogig.com',
      link: 'mailto:support@gogig.com',
    },
    {
      icon: 'lock',
      text: 'Privacy Policy',
      link: '*',
    },
  ],
};

export default data;
