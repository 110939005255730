import React, { Component } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import API from "../API";
import styled from "styled-components";
import logo from "../images/logo/truplay-logo-blue.png";
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';

class NewRegisterScreen extends Component {
  state = {
    form: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      fullName: "",
    },
    loading: false,
    error: null,
    data: {},
    empty: null,
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (
      this.state.form.fullName === "" ||
      this.state.form.email === "" ||
      this.state.form.password === ""
    ) {
      this.setState({
        empty: true,
      });
    } else {
      this.setState({ ...this.state, loading: true });
      API.noOptionsPost("/UserProfiles", {
        email: this.state.form.email,
        password: this.state.form.password,
        firstName: this.state.form.fullName.split(" ")[0],
        lastName: this.state.form.fullName.split(" ")[1],
      })
        .then((response) => {
          this.setState({
            ...this.state,
            data: response.data,
            loading: false,
            error: null,
            empty: null,
          });
        })
        .then(() =>
          API.noOptionsPost("/UserProfiles/login", {
            email: this.state.form.email,
            password: this.state.form.password,
          })
        )
        .then((response) => {
          let stringifiedToken = JSON.stringify(response.data);
          localStorage.setItem("userToken", stringifiedToken);
        })
        .then(() => {
          if (this.props.location.search.includes("recruiter")) {
            this.props.history.push("/recruiter/dashboard?disableCancel=true");
          } else {
            this.props.history.push("/getstarted");
          }
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            ...this.state,
            error: error,
            loading: false,
            data: {},
            empty: null,
          });
        });
    }
  };

  render() {
    return (
      <Container
        fluid={true}
        className="vh-100"
        style={{
          backgroundImage: `url(${require("images/landing-bg.png")})`,
          backgroundSize: "cover",
          opacity: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div class="overlay"></div>

        <CenterContainer
          class=""
          style={{
            backgroundImage: `url(${require("images/landing-blob.png")})`,
            backgroundSize: "cover",
          }}
        >
          <GoGigLogo src={logo} />
          <Title>Sign Up</Title>
          <Subtitle>Anonymous Job Search&trade;</Subtitle>
          <p style={{ color: "gray", fontSize: 12 }}>
            Your identity will remain anonymous until you choose to reveal
            yourself to a Hiring Professional.
          </p>
          {this.state.error !== null && (
            <Alert variant="danger">
              Email is already registered, Please login.
            </Alert>
          )}
          {this.state.empty !== null && (
            <Alert variant="danger">
              Please fill out all the fields to register.
            </Alert>
          )}
          <Form style={{}}>
            <Form.Row className="mt-4">
              <Col>
                <Form.Label>First and Last Name</Form.Label>
                <Form.Control
                  style={{
                    backgroundColor: "transparent",
                  }}
                  type="text"
                  placeholder="First and Last Name"
                  name="fullName"
                  onChange={this.handleChange}
                />
                <Form.Text className="text-muted">
                  Identity is anonymous until you choose to reveal it to a
                  Hiring Professional
                </Form.Text>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Label className="mt-4">Email address</Form.Label>
                <Form.Control
                  style={{
                    backgroundColor: "transparent",
                  }}
                  type="email"
                  name="email"
                  onChange={this.handleChange}
                  placeholder="Email address"
                />
                <Form.Text className="text-muted">
                  Use your email address. Your email remains private.
                </Form.Text>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col>
                <Form.Label className="mt-4">Password</Form.Label>
                <Form.Control
                  style={{
                    backgroundColor: "transparent",
                  }}
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={this.handleChange}
                />
                <Form.Text className="text-muted">
                  6 or more characters
                </Form.Text>
              </Col>
            </Form.Row>

            <Typography
                style={{
                  color: '#6c757d',
                  fontFamily: 'Montserrat',
                  fontSize: '14px',
                  marginTop: '20px',
                  marginBottom: '10px'
                }}
              >
                By registering you agree to our <a href="https://www.gogig.com/terms-and-conditions" style={{ fontWeight: '600', color: "inherit" }}>Terms and Conditions</a> and <Link to="/privacy-policy" style={{ fontWeight: '600', color: "inherit" }}>Privacy Policy</Link>
            </Typography>

            <Button
              variant="primary"
              type="submit"
              onClick={this.handleSubmit}
              className="d-block ml-auto mr-auto"
              style={{
                width: 300,
                fontSize: 24,
                padding: 4,
                fontWeight: "bold",
                marginBottom: 20,
                marginTop: 15,
              }}
            >
              Register
            </Button>
          </Form>
          <div class="divider">
            <hr class="left" />
            OR
            <hr class="right" />
          </div>
          {/* <img
            style={{ width: 300, marginTop: 10 }}
            src={require("images/signupwithfb.png")}
          /> */}
          <FooterText>
            Already have an account?{" "}
            <Link to="/login">
              <span style={{ fontWeight: "bold", color: "#002952" }}>
                Log In
              </span>
            </Link>
          </FooterText>
        </CenterContainer>
      </Container>
    );
  }
}

export default NewRegisterScreen;

const CenterContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1400px;
  @media (max-width: 500px) {
    padding: 20px;
    height: 100%;
    width: 100%;
  }
`;

const Divider = styled.p``;

const GoGigLogo = styled.img`
  position: absolute;
  left: 40px;
  width: 200px;
  height: auto;
  @media (max-width: 500px) {
    position: static;
  }
`;

const Title = styled.h1`
  color: red;
  font-weight: bold;
`;

const Subtitle = styled.p`
  margin-top: 10px;
  font-size: 20px;
`;

const FooterText = styled.p`
  font-size: 20px;
  margin-top: 20px;
`;
