import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LockIcon from '@material-ui/icons/Lock';
import MenuBookIcon from '@material-ui/icons/MenuBook';


import classes from './index.module.css';

function TemplateFooter(props) {
  return (
    <footer
      className={classes.footerContainer}
    >
      <Grid
        container
        className={classes.footerGrid}
      >
        <Grid
          item
          xs={0}
          lg={1}
        />
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={3}
        >
          <Link
            to="/privacy-policy"
            className={classes.footerLink}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <LockIcon
              style={{
                fill: '#fff',
              }}
            />
            <Typography
              style={{
                color: '#fff',
                fontSize: '0.75rem',
                fontFamily: 'Montserrat',
                marginLeft: '5px',
                marginTop: '3px'
              }}
            >
              PRIVACY POLICY
            </Typography>
          </Link>
        </Grid>
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={4}
        >
           {/* <Typography
            className={classes.footerText}
          >
            Connect with us
          </Typography> */}
          <a
            href="https://instagram.com/truplaygames?igshid=NTdlMDg3MTY="
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/instagram-skyblue-icon.svg"
              alt="Instagram Small Logo"
            />
          </a>
          <a
            href="https://www.facebook.com/truplaygames/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/facebook-skyblue-icon.svg"
              alt="Facebook Small Logo"
            />
          </a>
          <a
            href="https://twitter.com/TruplayGames/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/twitter-skyblue-icon.svg"
              alt="Twitter Small Logo"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/truplay/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/linkedin-skyblue-icon.svg"
              alt="LinkedIn Small Logo"
            />
          </a>
          <a
            href='https://www.truplaygames.com/'
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={classes.footerIcon}
              src="/icons/small-house-skyblue-icon.svg"
              alt="Home Logo"
            />
          </a>
        </Grid>
        <Grid
          className={classes.footerSectionContainer}
          item
          xs={12}
          lg={3}
        >
          <a target="_blank" href="https://www.gogig.com/">
            <img
              className={classes.footerGogigLogo}
              src="/icons/powered-by-skyblue-icon.svg"
              alt=""
            />
          </a>
        </Grid>
        <Grid
          item
          xs={0}
          lg={1}
        />
      </Grid>
    </footer>
  );
}

TemplateFooter.propTypes = {};

export default TemplateFooter;
