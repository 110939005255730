import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "../API";
import { Link } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarRecruiterWithItems } from "Stories/Navigation.stories.js";
import { Avatar } from "Stories/Avatars.stories.js";
import moment from "moment";

class RecruiterMessagesScreen extends Component {
  state = {
    recruiterSearchQueries: [],
    recruiterMatches: [],
    filteredMatches: [],
    messages: [],
    showDeleteModal: false,
    deleteProfile: {
      candidate: {
        anonymousUsername: "",
      },
    },
    deleteReason: "",
    messageInput: "",
    userMatch: {
      skills: [],
      candidate: {
        anonymousUsername: "",
        firstName: "",
        lastName: "",
        avatar: {
          slug: "",
        },
        userWritingSample: {
          receptiviti: {
            communication_recommendation: "",
          },
        },
        userPitch: "",
      },
      searchQuery: {
        hiringCompany: "",
        jobPosition: {
          name: "",
        },
        jobPositionSpecialty: {
          name: "",
        },
      },
    },
  };
  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}/recruiterSearchQueries`
    ).then((response) => {
      this.setState({
        recruiterSearchQueries: response.data,
      });
    });
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
        "include": {"recruiterMatches": ["candidate", "searchQuery", "skills"] }
    }`
    ).then((response) => {
      this.setState({
        ...response.data,
        filteredMatches: response.data.recruiterMatches.filter(
          (recruiterMatch) => recruiterMatch.candidateStatus === "accepted"
        ),
      });
    });

    API.get(
      `/UserMatches/${this.props.match.params.userMatchId}?filter={
        "include": ["messages", "searchQuery", "candidate", "recruiter", "skills"]
    }`
    ).then((response) => {
      this.setState({
        userMatch: response.data,
        messages: response.data.messages,
      });
      this.scrollToBottom();
    });

    this.timer = setInterval(() => this.getMessages(), 1000);
    this.timer2 = setInterval(() => this.getUserMatch(), 1000);
  }

  handleCancel = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  componentDidUpdate() {}

  getMessages = () => {
    API.get(
      `/UserMatches/${this.props.match.params.userMatchId}?filter={
        "include": ["messages", "searchQuery", "candidate", "recruiter", "skills"]
    }`
    ).then((response) => {
      this.setState({
        userMatch: response.data,
        messages: response.data.messages,
      });
      this.scrollToBottom();
    });
  };

  getUserMatch = () => {
    API.get(
      `/UserMatches/${this.props.match.params.userMatchId}?filter={
      "include": ["messages", "searchQuery", "candidate", "recruiter", "skills"]
  }`
    ).then((response) => {
      this.setState({
        userMatch: response.data,
      });
    });
    API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) => {
      this.setState(response.data);
    });
  };

  handleDeleteChat = (recruiterMatch) => {
    this.setState({
      showDeleteModal: true,
      deleteProfile: recruiterMatch,
    });
  };

  handleConfirmDelete = () => {
    API.delete(`/UserMatches/${this.state.deleteProfile.id}`).then(
      (response) => {
        API.get(
          `/UserProfiles/${this.props.userToken.userId}?filter={
          "include": {"recruiterMatches": ["candidate", "searchQuery", "skills"] }
      }`
        ).then((response) => {
          this.setState({
            ...response.data,
            showDeleteModal: false,
            filteredMatches: response.data.recruiterMatches.filter(
              (recruiterMatch) => recruiterMatch.candidateStatus === "accepted"
            ),
          });
        });
      }
    );
  };

  renderMessage = (message) => {
    if (message.messageFromId === this.props.userToken.userId) {
      return (
        <MyChatContainer>
          <MyChatMessageContainer>
            <MyChatMessage>{message.message}</MyChatMessage>
            <MyChatMessage style={{ fontSize: 10 }}>
              {moment(message.createdAt).fromNow()}
            </MyChatMessage>
          </MyChatMessageContainer>
        </MyChatContainer>
      );
    } else {
      return (
        <TheirChatContainer>
          <TheirChatImage>
            <Avatar
              width={50}
              height={50}
              character={message.messageFrom.avatar.slug}
            />
          </TheirChatImage>
          <TheirChatMessageContainer>
            <TheirChatMessage>{message.message}</TheirChatMessage>
            <TheirChatMessage style={{ fontSize: 10 }}>
              {moment(message.createdAt).fromNow()}
            </TheirChatMessage>
          </TheirChatMessageContainer>
        </TheirChatContainer>
      );
    }
  };

  scrollToBottom = () => {
    // this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    API.post(`/UserMessages`, {
      messageFromId: this.props.userToken.userId,
      messageToId: this.state.userMatch.candidate.id,
      matchId: this.props.match.params.userMatchId,
      message: this.state.messageInput,
      email: this.state.userMatch.candidate.email,
      pushId: this.state.userMatch.candidate.pushId,
      to: "candidate",
    });
    this.setState({
      messageInput: "",
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.timer2);
    this.timer = null;
    this.timer2 = null;
  }

  handleFilter = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });

    if (value !== "all") {
      let filteredMatches = this.state.recruiterMatches.filter(
        (rm) => rm.searchQueryId === value
      );

      this.setState({
        filteredMatches: filteredMatches.filter(
          (recruiterMatch) => recruiterMatch.candidateStatus === "accepted"
        ),
      });
    } else {
      this.setState({
        filteredMatches: this.state.recruiterMatches.filter(
          (recruiterMatch) => recruiterMatch.candidateStatus === "accepted"
        ),
      });
    }
  };

  render() {
    return (
      <Container
        style={{
          maxWidth: 1325,
        }}
        fluid={false}
      >
        <NavBarRecruiterWithItems {...this.props} />
        <Row className="mt-4" noGutters={true}>
          <Col
            md={3}
            style={{
              borderRight: "1px solid #f5f5f5",
            }}
          >
            <MessagesListWrapper>
              {/* <MessagesListSearchContainer>
                <MessagesSearch placeholder="Filter user.." />
              </MessagesListSearchContainer> */}
              <MessagesListContainer>
                <div>
                  <select
                    name="searchFilter"
                    onChange={this.handleFilter}
                    style={{
                      width: "100%",
                      outline: "none",
                      padding: 20,
                      border: "none",
                    }}
                  >
                    <option value="all">All</option>
                    {this.state.recruiterSearchQueries.map((rsq) => (
                      <option value={rsq.id}>{rsq.searchTitle}</option>
                    ))}
                  </select>
                </div>
                {this.state.filteredMatches.map((recruiterMatch) =>
                  recruiterMatch.candidateStatus === "accepted" ? (
                    <Link to={"/recruiter/messages/" + recruiterMatch.id}>
                      <MessagesListItemContainer
                        active={
                          recruiterMatch.id ===
                          this.props.match.params.userMatchId
                        }
                      >
                        <Avatar
                          width={50}
                          height={50}
                          character={recruiterMatch.candidate.avatar.slug}
                        />
                        <MessagesListItemDetailContainer>
                          <MessagesListItemDetailMsgPreview
                            style={{
                              fontWeight: "bold",
                              color: "#000",
                            }}
                          >
                            TruPlay User{" "}
                            {recruiterMatch.candidate.anonymousUsername}
                          </MessagesListItemDetailMsgPreview>
                          <MessagesListDeleteChatBtn
                            onClick={() =>
                              this.handleDeleteChat(recruiterMatch)
                            }
                          >
                            X
                          </MessagesListDeleteChatBtn>
                        </MessagesListItemDetailContainer>
                      </MessagesListItemContainer>
                    </Link>
                  ) : (
                    <Link to={"/recruiter/messages/" + recruiterMatch.id}>
                      <MessagesListItemContainer
                        active={
                          recruiterMatch.id ===
                          this.props.match.params.userMatchId
                        }
                      >
                        {recruiterMatch.candidate.avatar ? (
                          <Avatar
                            width={50}
                            height={50}
                            character={recruiterMatch.candidate.avatar.slug}
                          />
                        ) : (
                          <Avatar width={50} height={50} character="bear" />
                        )}
                        <MessagesListItemDetailContainer>
                          <MessagesListItemDetailMsgPreview
                            style={{
                              fontWeight: "bold",
                              color: "#000",
                            }}
                          >
                            TruPlay User{" "}
                            {recruiterMatch.candidate.anonymousUsername}
                          </MessagesListItemDetailMsgPreview>
                          <MessagesListItemDetailMsgPreview
                            style={{
                              color: "#999",
                            }}
                          >
                            {moment(recruiterMatch.createdAt).fromNow()}
                          </MessagesListItemDetailMsgPreview>
                          <MessagesListDeleteChatBtn
                            onClick={() =>
                              this.handleDeleteChat(recruiterMatch)
                            }
                          >
                            X
                          </MessagesListDeleteChatBtn>
                        </MessagesListItemDetailContainer>
                      </MessagesListItemContainer>
                    </Link>
                  )
                )}
              </MessagesListContainer>
            </MessagesListWrapper>
          </Col>
          <Col md={6}>
            <ChatContainer>
              <ChatListContainer>
                {this.state.messages.map((message) =>
                  this.renderMessage(message)
                )}
                {this.state.messages.length === 0 &&
                  this.state.userMatch.candidate.anonymousUsername && (
                    <MyChatContainer>
                      <MyChatMessageContainer style={{}}>
                        <MyChatMessage>
                          Congratulations! This candidate has accepted your
                          invitation to connect. Say something creative to get
                          the conversation started!
                        </MyChatMessage>
                        <MyChatMessage style={{ fontSize: 10 }}>
                          {moment(this.state.userMatch.createdAt).fromNow()}
                        </MyChatMessage>
                      </MyChatMessageContainer>
                    </MyChatContainer>
                  )}
                <div ref={(el) => (this.messagesEndRef = el)} />
              </ChatListContainer>
              <ChatReplyContainer
                style={{ display: "flex", alignItems: "center" }}
              >
                <Form onSubmit={this.handleSubmit} style={{ flexGrow: 1 }}>
                  <ChatReplyMessageInput
                    name="messageInput"
                    value={this.state.messageInput}
                    placeholder="Send Message"
                    onChange={this.handleChange}
                    disabled={this.state.userMatch.candidateVeil ? true : false}
                  />
                </Form>
                <Button
                  onClick={this.handleSubmit}
                  style={{
                    height: 40,
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  Send
                </Button>
              </ChatReplyContainer>
            </ChatContainer>
          </Col>
          <Col
            md={3}
            style={{
              borderLeft: "1px solid #f5f5f5",
            }}
          >
            {this.state.userMatch.candidate.firstName === "" ? (
              <ChatDetailContainer>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 20,
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      color: "#999",
                      textAlign: "center",
                      fontSize: 12,
                    }}
                  >
                    After you have a match, select the candidate you want to
                    message. The profile for the candidate will show here.
                  </p>
                </div>
              </ChatDetailContainer>
            ) : (
              <ChatDetailContainer>
                <ChatDetailImage>
                  {this.state.userMatch.candidate.avatar ? (
                    <Avatar
                      width={100}
                      height={100}
                      character={this.state.userMatch.candidate.avatar.slug}
                    />
                  ) : (
                    <Avatar width={100} height={100} character="bear" />
                  )}
                </ChatDetailImage>
                <ChatDetailName>
                  {this.state.userMatch.candidateVeil
                    ? "TruPlay User " +
                      this.state.userMatch.candidate.anonymousUsername
                    : this.state.userMatch.candidate.firstName +
                      " " +
                      this.state.userMatch.candidate.lastName}
                </ChatDetailName>
                <p style={{ fontSize: 14, color: "#999" }}>
                  {"TruPlay User " +
                    this.state.userMatch.candidate.anonymousUsername}
                </p>
                <img
                  style={{ marginTop: 5, marginBottom: 5 }}
                  src={require("images/icons/Search.svg")}
                />
                <ChatDetailDescription
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  {this.state.userMatch.candidate.userPitch}
                </ChatDetailDescription>
                <ChatDetailInfoContainer>
                  <ChatDetailInfoTitle>Email </ChatDetailInfoTitle>
                  <ChatDetailInfoResponse>
                    {this.state.userMatch.candidateVeil
                      ? "Hidden"
                      : this.state.userMatch.candidate.email}
                  </ChatDetailInfoResponse>
                </ChatDetailInfoContainer>
                <ChatDetailInfoContainer>
                  <ChatDetailInfoTitle>Position Type</ChatDetailInfoTitle>
                  <ChatDetailInfoResponse>
                    {this.state.userMatch.searchQuery.jobPosition.name}
                  </ChatDetailInfoResponse>
                </ChatDetailInfoContainer>
                <ChatDetailInfoContainer>
                  <ChatDetailInfoTitle>Position Specialty</ChatDetailInfoTitle>
                  <ChatDetailInfoResponse>
                    {this.state.userMatch.searchQuery.jobPositionSpecialty.name}
                  </ChatDetailInfoResponse>
                </ChatDetailInfoContainer>
                <ChatDetailInfoContainerCol>
                  <ChatDetailInfoTitle style={{ textAlign: "center" }}>
                    Skills Evaluation
                  </ChatDetailInfoTitle>
                </ChatDetailInfoContainerCol>
                {this.state.userMatch.skills.length === 0 && (
                  <ChatDetailInfoContainer>
                    <ChatDetailInfoResponse>
                      No Skills Requested
                    </ChatDetailInfoResponse>
                  </ChatDetailInfoContainer>
                )}
                {this.state.userMatch.skills.map((skill) => (
                  <ChatDetailInfoContainer>
                    <ChatDetailInfoTitle>{skill.skillName}</ChatDetailInfoTitle>
                    <ChatDetailInfoResponse>
                      {
                        {
                          0: "None",
                          1: "Beginner",
                          2: "Intermediate",
                          3: "Advanced",
                          4: "World Class",
                        }[skill.experienceMin]
                      }
                    </ChatDetailInfoResponse>
                  </ChatDetailInfoContainer>
                ))}
                <img
                  style={{ marginTop: 10, marginBottom: 10 }}
                  src={require("images/icons/CommunicationRecommendation.svg")}
                />
                <ChatDetailInfoContainerCol>
                  <ChatDetailInfoTitle
                    style={{ fontSize: 14, textAlign: "center" }}
                  >
                    Communication Recommendation
                  </ChatDetailInfoTitle>
                  <ChatDetailInfoResponse
                    style={{
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                    {this.state.userMatch.candidate.userWritingSample
                      ? this.state.userMatch.candidate.userWritingSample
                          .receptiviti.communication_recommendation
                      : "No Recommendation"}
                  </ChatDetailInfoResponse>
                </ChatDetailInfoContainerCol>
                <ChatDetailInfoContainerCol>
                  <ChatDetailInfoResponse>
                    <Link
                      to={`/recruiter/candidate/${this.state.userMatch.candidate.id}/${this.state.userMatch.searchQuery.id}`}
                    >
                      <Button size="sm" block>
                        View Profile
                      </Button>
                    </Link>
                  </ChatDetailInfoResponse>
                </ChatDetailInfoContainerCol>
              </ChatDetailContainer>
            )}
          </Col>
        </Row>
        <Modal show={this.state.showDeleteModal} size="lg" centered>
          <Modal.Body>
            <ModalContainer>
              <ModalColumn>
                <img
                  style={{
                    height: 250,
                  }}
                  src={require("images/modals/man-thinking.png")}
                />
              </ModalColumn>
              <ModalColumn className="p-4 w-100">
                <h1>Confirm Deletion!</h1>
                <p>
                  Please confirm that you want to delete your conversation with
                  TruPlay User{" "}
                  {this.state.deleteProfile.candidate.anonymousUsername}.
                </p>
                <Form>
                  <Form.Row>
                    <Col>
                      <Form.Label>
                        Why are you deleting the conversation with this
                        candidate?
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="deleteReason"
                        value={this.state.deleteReason}
                        onChange={this.handleChange}
                      >
                        <option disabled value="">
                          -- select an option --
                        </option>
                        <option value="1">
                          I've gotten the candidate I need from this TruPlay Careers
                          Search
                        </option>
                        <option value="2">
                          I've gotten the candidate I need from somewhere
                          besides TruPlay Careers
                        </option>
                        <option value="3">
                          I don't need to hire for this position anymore for
                          some other reason
                        </option>
                        <option value="4">Other (please share)</option>
                      </Form.Control>
                    </Col>
                  </Form.Row>
                  <Form.Row className="mt-3">
                    <Button
                      variant="outline-primary"
                      onClick={this.handleCancel}
                      className="mr-2"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="ml-auto"
                      onClick={this.handleConfirmDelete}
                    >
                      Delete
                    </Button>
                  </Form.Row>
                </Form>
              </ModalColumn>
            </ModalContainer>
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

export default RecruiterMessagesScreen;

const MessagesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 800px;
  overflow-y: scroll;
`;
const MessagesListSearchContainer = styled.div``;
const MessagesSearch = styled.input`
  border: none;
  padding: 20px;
  width: 100%;
  outline: none;
  border-bottom: 1px solid #f5f5f5;
  font-size: 14px;
`;
const MessagesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 800px;
  background: white;
`;

const MessagesListItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background: white;
  padding: 20px;
  border-bottom: 1px solid #f5f5f5;

  background-color: ${(props) => (props.active ? "#f5f5f5" : "#ffff")}
  :hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

const MessagesListItemImage = styled.img`
  border-radius: 50px;
  width: 50px;
  height: 50px;
`;
const MessagesListItemDetailContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  flex: 3;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`;
const MessagesListItemDetailTitle = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 12px;
`;
const MessagesListItemDetailMsgPreview = styled.p`
  color: silver;
  font-size: 12px;
  margin: 0;
  flex-grow: 1;
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
`;

const MessagesListDeleteChatBtn = styled.div`
  font-size: 16px;
  color: #999;
`;

const MessagesListItemTime = styled.p`
  color: silver;
  font-size: 12px;
  margin: 0;
`;

const ChatContainer = styled.div`
  background: white;
`;
const ChatListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: calc(800px - 62px);
  overflow-y: scroll;
`;
const MyChatContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 10px;
`;
const MyChatMessageContainer = styled.div`
  background: #0e89df;
  border-radius: 8px;
  padding: 20px;
  max-width: 300px;
`;
const MyChatMessage = styled.p`
  color: white;
  font-size: 14px;
  margin: 0;
`;
const TheirChatContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  margin: 10px;
`;
const TheirChatImage = styled.div`
  margin-right: 20px;
`;
const TheirChatMessageContainer = styled.div`
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  max-width: 300px;
`;
const TheirChatMessage = styled.p`
  color: #999;
  font-size: 14px;
  margin: 0;
`;
const ChatReplyContainer = styled.div``;
const ChatReplyMessageInput = styled.input`
  outline: none;
  border: none;
  border-top: 1px solid #f5f5f5;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  color: #999;
`;

const ChatDetailContainer = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 800px;
  max-height: 800px;
`;
const ChatDetailImage = styled.div`
  margin-top: 0px;
`;
const ChatDetailName = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  margin: 0;
  margin-top: 40px;
  text-align: center;
`;
const ChatDetailTitle = styled.p`
  color: #999;
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
`;
const ChatDetailDescription = styled.p`
  color: #999;
  font-size: 12px;
  margin: 0;
  text-align: center;
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3.6em;
  line-height: 1.8em;
`;

const ChatDetailInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`;

const ChatDetailInfoContainerCol = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
`;

const ChatDetailInfoTitle = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  margin-top: 5px;
`;
const ChatDetailInfoResponse = styled.p`
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #999;
`;

const BackgroundImg = styled.div`
  width: 400px;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://farm4.staticflickr.com/3902/14985871946_24f47d4b53_h.jpg");
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  margin: -16px;
`;
const ModalColumn = styled.div`
  justify-content: center;
`;
