const data = {
  myCarePath: {
    desktopLogo: '/images/truplay-logo-blue.png',
    mobileLogo: '/images/truplay-logo-blue.png',
    footerLinks: [
      {
        icon: 'email',
        text: 'support@gogig.com',
        link: 'mailto:support@gogig.com',
      },
      {
        icon: 'lock',
        text: 'Privacy Policy',
        link: '*',
      },
    ],
  },
};

export default data;
