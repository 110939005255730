import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { scroller } from 'react-scroll';
import { Grid, Typography, Button, Box } from '@material-ui/core';

import classes from './index.module.css';

function RootBodyTemplate(props) {
  const {
    contentHeroLogo,
    contentHeroImg,
    contentHeroTitle1,
    contentHeroTitle2,
    contentHeroDescription,
    contentHeroButtonText,
    contentHeroButtonLink,
    contentHeroBelowButtonText,
    signInText,
    recentlyAdded,
    contentBody,
    contentBodyButtonText,
    contentBodyButtonLink,
  } = props;
  
  const ellipseHeight = 200;
  const characterImgRef = useRef(null);
  const titleContainerRef = useRef(null);
  const heroLeftSpaceRef = useRef(null);
  const imgStarsRef = useRef(null);
  const imgCharacterRef = useRef(null);
  const [characterPos, changeCharacterPos] = useState({ width: '100px', bottom: 0, left: 0 });
  const [starPos, changeStarPos] = useState({width: '100px', bottom: 0, left: 0});
  const [firstRender, changeFirstRender] = useState(false);
  

  useEffect(() => {
    const characterWidth = characterImgRef.current.clientWidth;
    const heroLeftSpaceWidth = heroLeftSpaceRef.current.clientWidth;

    changeStarPos(star => ({
      // bottom: titleContainerBottom - (293 * 0.8),
      ...star,
      width: heroLeftSpaceWidth,
    }));
    changeCharacterPos(char => ({
      ...char,
      width: characterWidth * 1.5,
      // bottom:  characterImgBottom - (537 * 0.95),
      
    }));

    // Calculates StarsImage Height
    imgStarsRef.current.addEventListener('load', () => {
      const titleContainerBottom = titleContainerRef.current.getBoundingClientRect().bottom + window.scrollY;
      const titleContainerLeft = titleContainerRef.current.getBoundingClientRect().left;
      const imgStarsHeight = imgStarsRef.current.clientHeight;
      const heroLeftSpaceWidth = heroLeftSpaceRef.current.clientWidth;
      changeStarPos(star => ({
        ...star,
        left: titleContainerLeft - ((heroLeftSpaceWidth * 2 ) / 3),
        bottom: titleContainerBottom - (imgStarsHeight * 0.8) - (ellipseHeight * 0.15),
      }));
    });

    // Calculates CharactersImage Height
    imgCharacterRef.current.addEventListener('load', () => {
      const characterImgBottom = characterImgRef.current.getBoundingClientRect().bottom + window.scrollY;
      const characterImgLeft = characterImgRef.current.getBoundingClientRect().left;
      const characterWidth = characterImgRef.current.clientWidth;
      const imgCharacterHeight = imgCharacterRef.current.clientHeight;
      changeCharacterPos(char => ({
        ...char,
        bottom: characterImgBottom - (imgCharacterHeight * 0.95),
        left: characterImgLeft - ((characterWidth * 0.5) / 4),
      }))
    });
  }, [])

  const scrollToBodyContent = () => {
    scroller.scrollTo('bodyContent', {
      duration: 800,
      delay: 0,
      offset: -90,
      smooth: 'easeInOutQuart',
    });
  };

  const bodyText = (
    <Grid
      className={clsx("bodyContent", classes.bodyTextContainer)}
      container
      style={{
        zIndex: '200',
        transform: `translateY(-${ellipseHeight}px)`,
      }}
    >
      <Grid
        container
      >
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            '@media (maxWidth: 901px)': {
              padding: '0 20px',
            },
          }}
        >
          <Typography
            variant="h2"
            className={classes.bodyTitle}
          >
            Ways the TruPlay Careers Platform Can Benefit You
          </Typography>
        </Grid>
        <Grid
          container
          style={{
            marginTop: '6.25rem',
            marginBottom: '3.125rem',
          }}
        >
          <Grid
            item
            xs={0}
            lg={2}
          />
          <Grid
            item
            container
            xs={12}
            lg={8}
          >
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/message-skyblue-icon.svg"
                  alt=""
                />
                <Typography
                  className={classes.cardText}
                >
                  Entertain conversations about potential career advancement without jeopardizing your current role
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/bag-skyblue-icon.svg"
                  alt=""
                />
                <Typography
                  className={classes.cardText}
                >
                  Sign up with TruPlay and our hiring team will reach out to you when suitable opportunities arise - no résumé required
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/collab-hands-skyblue-icon.svg"
                  alt=""
                />
                <Typography
                  className={classes.cardText}
                >
                  Anonymity in the platform will remove all implicit bias and discrimination in the hiring process
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/megaphone-skyblue-icon.svg"
                  alt=""
                />
                <Typography
                  className={classes.cardText}
                >
                  Stay informed and up to date with the latest information
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const landingContent = (
    <>
      <Grid
        container
        className={clsx(classes.heroContainer, classes.heroBorders)}
        style={{
          overflow: 'hidden',
        }}
      >
        <Grid
          className={classes.heroImgContainer}
          item
          container
          xs={12}
        >
          <div
            style={{
              backgroundImage: `url(${contentHeroImg})`,
              position: 'absolute',
              zIndex: '1',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: '25% 100%',
            }}
          />
          <Grid
            ref={heroLeftSpaceRef}
            item
            xs={0}
            lg={2}
            className={classes.heroContainerItems}
          />
          <Grid
            ref={titleContainerRef}
            item
            xs={12}
            lg={4}
            className={clsx(classes.heroContainerItems, classes.heroContainerItemTitle)}
          >
            <img
              src={contentHeroLogo}
              alt=""
            />
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle1 }
            </Typography>
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle2 }
            </Typography>
            <Typography
              className={classes.heroDescription}
            >
              { contentHeroDescription }
            </Typography>
            <Button
              className={classes.buttonSkyBlueGradient}
              href={contentHeroButtonLink}
              style={{
                margin: '20px 0',
                minWidth: '200px',
                height: '44px',
                fontWeight: '700',
              }}
            >
              { contentHeroButtonText }
            </Button>
            <Link
              to="/info"
            >
              <Typography
                className={classes.heroSubDescription}
              >
                { contentHeroBelowButtonText }
              </Typography>
            </Link>
          </Grid>
          <Grid
            ref={characterImgRef}
            xs={0}
            lg={4}
            className={clsx(classes.heroContainerItems, classes.heroContainerCharacters)}
          />
        </Grid>
        <Box
          style={{
            height: '200px'
          }}
        />
      </Grid>
      <Box
        className={classes.ellipse}
        style={{
          zIndex: '100',
          height: `${ellipseHeight}px`,
          width: '100%',
          backgroundColor: '#f7f8f9',
          transform: `translateY(-${ellipseHeight / 2}px)`,
          borderTopLeftRadius: '30% 60%',
          borderTopRightRadius: '80% 100%',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          scale: '1.15',
        }}
      />
      <img
        className={classes.imgStarsStyle}
        ref={imgStarsRef}
        style={{
          zIndex: '200',
          position: 'absolute',
          top: starPos.bottom,
          left: starPos.left,
        }}
        src="/images/hero-stars.svg"
        alt=""
      />
      <img
        ref={imgCharacterRef}
        style={{
          zIndex: '200',
          position: 'absolute',
          height: 'auto',
          width: characterPos.width,
          top: characterPos.bottom,
          left: characterPos.left,
        }}
        src="/images/hero-characters.svg"
        alt=""
      />
      { bodyText }
      <Grid
        container
        style={{
          padding: '2.5rem 0',
        }}
      >
        <Grid
          item
          xs={1}
          lg={2}
        />
        <Grid
          item
          xs={10}
          lg={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <Typography
            className={classes.bodyLastDescription}
          >
            Are you interested in hiring talent from our community?
          </Typography> */}
          <Button
            className={classes.buttonSkyBlueGradient}
            href={contentBodyButtonLink}
            style={{
              margin: '20px 0',
              width: '200px',
              height: '44px',
              fontWeight: '700',
            }}
          >
            { contentBodyButtonText }
          </Button>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid
      container
      className={classes.contentContainer}
    >
      {
        landingContent
      }
    </Grid>
  );
}

RootBodyTemplate.propTypes = {}

export default RootBodyTemplate
