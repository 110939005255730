import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

const classes = {
  privacyTitle: {
    color: '#4d4d4d',
    fontSize: '1.125rem',
    fontFamily: 'Roboto',
  },
  privacyContent: {
    color: '#4d4d4d',
    fontSize: '0.875rem',
    fontFamily: 'Roboto',
    lineHeight: '16px',
  }
}

const truplayPrivacyPolicy = (props) => (
  <>
    <Typography
      className={classes.privacyTitle}
      component="h6"
    >
      Privacy Policy
    </Typography>
    <Typography
      className={classes.privacyContent}
      component="p"
    >
      The following “Privacy Policy” (the “Privacy Policy”) is incorporated into each and every Services Agreement and/or other agreement between GoGig and You (each an/the “Agreement”); the defined terms of each Agreement in question, and all of the linked content referenced in the “Terms and Conditions” at
      {' '}
      <a href="https://www.gogig.com/terms-and-conditions" target="_blank" rel="noreferrer">www.gogig.com/terms-and-conditions</a>
      , are incorporated herein by reference.
    </Typography>
    <ul>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Purpose of Privacy Policy. GoGig is strongly committed to protecting the privacy of Your personal information. The Privacy Policy set forth hereinafter is applicable to Your use of the Services and GoGig’s web site, including any interactive features or downloads that are operated by GoGig that are available through GoGig’s web site, whether accessed or used via personal computers, mobile devices, or otherwise (collectively included in the definition of “Services”). The Privacy Policy is produced, posted, and maintained by GoGig. The Privacy Policy identifies the kinds of personal information GoGig may gather during Your use of the Services, explains why GoGig gathers Your personal information, for what purposes GoGig uses Your personal information, under what circumstances that GoGig might disclose Your personal information, and how You can manage Your personal information. You understand and agree that the practices described in the Privacy Policy apply only to information collected through Your use of the Services and does not apply to information that You may submit to GoGig offline, to Web sites like Facebook, or to Web sites maintained by other companies or organizations to which GoGig may link.
        </Typography>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Modification of Privacy Policy. GOGIG MAY MODIFY THE PRIVACY POLICY AT ANY TIME, EFFECTIVE UPON THE SOONER OF POSTING THE MODIFIED POLICY ON THE GOGIG WEB SITE OR WRITTEN NOTICE, IF GIVEN, TO YOU. YOU AGREE TO COMPLY WITH SUCH MODIFIED POLICY AND ARE ADVISED TO REGULARLY ACCESS THE WEB SITE OF GOGIG AND REVIEW THE CURRENT VERSION OF PRIVACY POLICY.
        </Typography>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Questions about Privacy Policy. If You have any questions about the Privacy Policy, please contact GoGig by email at
          {' '}
          <a href="mailto:info@gogig.com">info@gogig.com</a>
          .
        </Typography>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Controller of Personal Information. Any of Your personal information provided to, or to be gathered by, GoGig is controlled primarily by GoGig at
          {' '}
          <a href="https://goo.gl/maps/8FF4uLUYWT29BmQb9" target="_blank" rel="noreferrer">499 South Federal Highway, #239, Boynton Beach, FL 33435</a>
          .
        </Typography>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Information Collected and Stored. GoGig adheres to high standards of ethical practices and protects the privacy of the users of the Services. Under the Privacy Policy, GoGig doesn’t give or share Your personal information to/with any company not affiliated with GoGig. There are two types of personal information collected by GoGig, the personal information supplied by You and the non-personal information collected automatically through Your use of the Services.
        </Typography>
        <ul>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              5.01 Personal Information Supplied by You. GoGig collects personal information about You that You supply to GoGig, to use the Services, which may include:
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              5.01.1. Your user name and password; and
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              5.01.2. information that You provide in conjunction with requests for information for or in conjunction with Your account or technical support of the Services.
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              5.02. Non-Personal Information Collected Automatically. When You interact with the Services, GoGig servers may collect and keep activity logs and other information that do not identify You individually, but may store or automatically log the following information:
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              5.02.1. about Your computer, mobile device, or other device that You use to access the Services, such as Your device ID or IP address, geo-location information, unique device identifiers, browser type, browser language, and other transactional information;
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              5.02.2. about Your use of the Services, which includes a reading history of the pages You view that GoGig uses to provide You with a more-customized experience while using the Services;
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              5.02.3. additional “traffic data,” such as time of access, date of access, software crash reports, session identification number, access times, and referring Web-site addresses; and
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              5.02.4. demographic data, such as age, gender, and five-digit zip code.
            </Typography>
          </li>
        </ul>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Use Cookies or Spyware. GoGig does not use and will not install spyware on Your device, nor does GoGig use spyware to retrieve information from Your device. GoGig does use cookies, which are small text files that are stored on Your device, when You visit certain Web pages that record Your preferences; GoGig uses cookies to track use of the Services and may also use cookies to monitor traffic, improve any or all of the Services, or make the Services more relevant for Your use. You may get cookies from third-party Web sites and advertisers while using the Services; GoGig does not control these third-party cookies, and they are not subject to the Privacy Policy.
        </Typography>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Retention of Personal Information. GoGig may retain Your personal information so long as You are using the Services, maintain an account with GoGig, and for up to twelve (12) months thereafter.
        </Typography>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Information of Children. The Services are not knowingly directed to children, defined as individuals under eighteen years of age. GoGig does not knowingly collect personal information from children. If You are a child, You should not use any of the Services, and under no circumstances should You provide personal information to GoGig. If GoGig discovers that a child has provided GoGig with personal information, GoGig will immediately delete that child’s personal information from GoGig’s data records.
        </Typography>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Use of Personal Information and Non-Personal Information. GoGig may use Your personal information and non-personal information to allow GoGig to personalize and improve the Services, to prevent or detect fraud, illegal uses, or abuses of the Services, and to prevent or detect violations of its Acceptable Use Policy. GoGig may use or disclose Your personal information and non-personal information if required to do so by law or in the good faith belief that such action is necessary to:
        </Typography>
        <ul>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              9.01. conform to applicable law or comply with legal process served on GoGig;
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              9.02. protect and defend GoGigs rights, property, Services, or users; and/or
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              9.03. act under emergency circumstances to protect the personal safety of GoGig personnel, GoGig’s affiliates, agents, and independent contractors, other users of the Services, or the public. GoGig may use Your personal information to respond to Your requests for information, process payments, update GoGig’s records, and generally maintain Your accounts and relationship with GoGig. GoGig may also use Your personal information to prepare reports, recommendations, provide services at Your request, provide customer service, and track Your compliance with rules and regulations applicable to the Services. Except as disclosed in the Privacy Policy, GoGig does not convey or disclose Your personal information to any companies not affiliated with GoGig; GoGig may have certain third parties, such as affiliates, agents, and independent contractors, perform business functions on GoGig’s behalf, such as marketing, development, and testing of Services, analytics, credit card processing, customer service, and fraud protection; these third parties may have access to and use Your personal information as needed to perform the required business functions related to the Services, but are under an obligation to maintain the confidentiality and security of Your personal information, and are also restricted from using, distributing, or altering Your personal information in any way other than to provide such business functions. GoGig may use Your non-personal information to aid GoGig in determining how people use the Services and in identifying the users of the Services. GoGig may also use Your non-personal information to provide statistical “ratings” information in aggregated form to third parties about how its users collectively use the Services. GoGig may allow use or allow access to Your non-personal information in any other manner that GoGig deems appropriate or necessary in the ordinary course of GoGig’s business.
            </Typography>
          </li>
        </ul>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Efforts to Keep Personal Information Secure. The importance of security for Your personal information is important to GoGig. GoGig exercises great care in designing the Services to provide secure transmission of Your information while using the Services. Your personal information is stored in secure, operating environments that are not readily available to the public. If You purchase something from GoGig using Your credit card, GoGig reveals only the last five digits of Your credit-card number when confirming Your order to You. GoGig may request proof of identity before it discloses Your personal information to You. While GoGig makes good faith efforts to safeguard Your personal information and to secure Your data during Your use of the Services, no transmission of data over the Internet, wireless network, or any other public network can be guaranteed to be 100% secure, and GoGig does not assume any responsibility for any breach that may occur as a result thereof; in this regard, Your use of the Services is at Your own risk.
        </Typography>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Your Obligations to Provide, Maintain, and Secure Your Information. If You order any of the Services, You promise to:
        </Typography>
        <ul>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              11.01. provide GoGig with true, accurate, current, and complete information about You and Your business as prompted by during the order of the Services; and
            </Typography>
          </li>
          <li>
            <Typography
              className={classes.privacyContent}
              component="p"
            >
              11.02. maintain and promptly update Your information to keep it true, accurate, current, and complete.If You provide any information to GoGig that is untrue, inaccurate, not current, or incomplete, or GoGig reasonably suspects that such information is untrue, inaccurate, not current, or incomplete, GoGig has the right to suspend or terminate Your account and your further use of the Services. You are solely responsible for the security and confidentiality of any password used to access Your account or the Services and for any and all activities, whether authorized or unauthorized, that occur under Your account. You will immediately notify GoGig of any unauthorized use of Your account or any other breach of security of which You become aware. You are solely responsible for taking precautions and providing any necessary security measures for Your account and intended use of the Services. YOU UNDERSTAND AND AGREE THAT ANY INFORMATION YOU SHARE IN PUBLIC AREAS, SUCH AS SURVEY SITES OR FEEDBACK SECTIONS, BECOMES PUBLIC; THE PRIVACY POLICY DOES NOT APPLY TO ANY INFORMATION YOU MAKE PUBLIC; YOU UNDERSTAND AND AGREE NOT TO PUBLICLY DISCLOSE ANY PERSONAL INFORMATION THAT YOU EXPECT TO KEEP PRIVATE.
            </Typography>
          </li>
        </ul>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Governing Law. The Services are provided in the United States in accordance with the Privacy Policy. If You are uncertain whether the Privacy Policy conflicts with any applicable privacy laws, such as those applicable to where You are located, You should not submit Your personal information to GoGig. IF YOU ARE LOCATED IN A COUNTRY OUTSIDE THE UNITED STATES AND VOLUNTARILY SUBMIT PERSONAL INFORMATION TO GOGIG, YOU THEREBY CONSENT TO THE USE OF SUCH PERSONAL INFORMATION AS PROVIDED IN THE PRIVACY POLICY AND TO THE TRANSFER OF THAT PERSONAL INFORMATION TO, AND/OR STORAGE OF THAT INFORMATION IN, THE UNITED STATES REGARDLESS OF WHETHER THE COUNTRY WHERE YOU ARE LOCATED DEEMS THE PRIVACY POLICY TO BE ADEQUATE. California Civil Code Section 1798.83 permits customers of GoGig who are California residents to request certain information regarding the disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to
          {' '}
          <a href="mailto:info@gogig.com">info@gogig.com</a>
          .
        </Typography>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Change of Ownership or Organization-Type. The ownership or organization type of GoGig may change while providing the Services; if such an event occurs, you understand and consent to GoGig’s transfer of Your personal information as a result of such business transaction; if such an even occurs, GoGig will make reasonable effort to require the new ownership or organization-type to follow the Privacy Policy.
        </Typography>
      </li>
      <li>
        <Typography
          className={classes.privacyContent}
          component="p"
        >
          Correcting Your Inaccurate Personal Information. You can correct Your inaccurate personal information by sending an email message to
          {' '}
          <a href="mailto:info@gogig.com">info@gogig.com</a>
          .
        </Typography>
      </li>
    </ul>
  </>
);

truplayPrivacyPolicy.propTypes = {};

export default truplayPrivacyPolicy;
