import axios from "axios";

class API {
  BASE_URL: string;

  constructor() {
    this.BASE_URL = "https://truplaycareers.com:4000/api";
  }

  /**
   * Generate the request options
   *
   * By default it loads the active access userToken and sets it as the
   * Authorization header
   **/
  async _generateOptions(options) {
    let userToken = await localStorage.getItem("userToken");
    userToken = JSON.parse(userToken);
    return {
      headers: {
        Authorization: `${userToken.id}`,
      },
    };
  }

  async _generateFileOptions(options) {
    let userToken = await localStorage.getItem("userToken");
    userToken = JSON.parse(userToken);
    return {
      headers: {
        Authorization: `${userToken.id}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }

  /**
   * Generate a fully formed URL from a relative path
   *
   * URL base is set using this.BASE_URL
   **/
  _generateUrl(path: string): string {
    let url = `${this.BASE_URL}${path}`;
    return url;
  }

  async delete(path: string, options: any = {}): Promise<any> {
    return axios.delete(
      this._generateUrl(path),
      await this._generateOptions(options)
    );
  }

  async post(path: string, body: any = {}, options: any = {}): Promise<any> {
    return axios.post(
      this._generateUrl(path),
      body,
      await this._generateOptions(options)
    );
  }

  async noOptionsPost(
    path: string,
    body: any = {},
    options: any = {}
  ): Promise<any> {
    return axios.post(this._generateUrl(path), body);
  }

  async noOptionsGet(
    path: string,
    body: any = {},
    options: any = {}
  ): Promise<any> {
    return axios.get(this._generateUrl(path));
  }

  async file(path: string, body: any = {}, options: any = {}): Promise<any> {
    return axios.post(
      this._generateUrl(path),
      body,
      await this._generateFileOptions(options)
    );
  }

  async patch(path: string, body: any = {}, options: any = {}): Promise<any> {
    return axios.patch(
      this._generateUrl(path),
      body,
      await this._generateOptions(options)
    );
  }

  async get(path: string, options: any = {}): Promise<any> {
    return axios.get(
      await this._generateUrl(path),
      await this._generateOptions(options)
    );
  }

  async search(path: string, options: any = {}): Promise<any> {
    return axios.get(
      process.env.REACT_APP_API_ENDPOINT + path,
      await this._generateOptions(options)
    );
  }

  async put(path: string, body: any = {}, options: any = {}): Promise<any> {
    return axios.put(
      this._generateUrl(path),
      body,
      await this._generateOptions(options)
    );
  }
}

const _API = new API();

export default _API;

