import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { scroller } from 'react-scroll';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import classes from './index.module.css';
import mycarepathPrivacy from '../../constants/mycarepathPrivacyPolicy';
import { signUpRequest } from '../../constants/endpoints';

const useStyles = makeStyles({
  select: {
    width: '90%',
    padding: '8.5px 14px',
    fontFamily: 'Lato',
  },
  popoverRoot: {
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) !important',
  },
});

function TemplateInfo(props) {
  const {
    contentHeroImg,
    contentHeroTitle1,
    contentHeroTitle2,
    contentHeroDescription,
    contentHeroButtonText,
    contentHeroButtonLink,
    recentlyAdded,
    contentBody,
    privacyForm,
    changePrivacyForm,
  } = props;
  const ellipseHeight = 200;
  const starsContainerRef = useRef(null);
  const imgStarsRef = useRef(null);

  const [starPos, changeStarPos] = useState({width: '100px', bottom: 0, left: 0});
  const [firstName, changeFirstName] = useState('');
  const [lastName, changeLastName] = useState('');
  const [emailAddress, changeEmailAddress] = useState('');
  const [emailValidation, changeEmailValidation] = useState(false);
  const [phoneNumber, changePhoneNumber] = useState('');
  const [selectedOption, changeSelectedOption] = useState('');
  const [confirmationForm, changeConfirmationForm] = useState(false);
  const styles = useStyles();

  const scrollToBodyContent = () => {
    scroller.scrollTo('bodyContent', {
      duration: 800,
      delay: 0,
      offset: -90,
      smooth: 'easeInOutQuart',
    });
  };

  const handleChangePrivacyForm = () => {
    changePrivacyForm(!privacyForm);
  };

  const handleFormFieldsClear = () => {
    changeFirstName('')
    changeLastName('')
    changeEmailAddress('')
    changePhoneNumber('')
    changeSelectedOption('')
  }

  const handleEmailValidation = () => {
    const regex = /\w+[\w._%+-]+@[\w.-]+\.[a-zA-Z]{2,3}/;
    if (!regex.test(emailAddress)) {
      changeEmailValidation(true);
      return null;
    }
    const request = {
      method: 'POST',
      data: {
        firstName,
        lastName,
        truplayReferral: selectedOption,
        email: emailAddress,
        phoneNumber,
      },
    };
    signUpRequest(request);
    changeConfirmationForm(true);
  };

  const handleFormClose = () => {
    changeConfirmationForm(false);
    handleFormFieldsClear()
  };

  useEffect(() => {
    const starsContainerWidth = starsContainerRef.current.clientWidth;
    changeStarPos(star => ({
      ...star,
      width: starsContainerWidth,
    }));

    imgStarsRef.current.addEventListener('load', () => {
      const starsContainerWidth = starsContainerRef.current.clientWidth;
      const starsContainerLeft = starsContainerRef.current.getBoundingClientRect().left;
      const starsContainerBottom = starsContainerRef.current.getBoundingClientRect().bottom;
      const imgStarsHeight = imgStarsRef.current.clientHeight;
      changeStarPos(star => ({
        ...star,
        bottom: starsContainerBottom - (imgStarsHeight * 0.35) - (ellipseHeight * 0.60),
        left: starsContainerLeft - (starsContainerWidth * 0.2),
      }));
    });
  }, [])

  const privacyContent = (
    <Grid
      container
      style={{
        minHeight: '100vh',
        backgroundColor: '#ccc',
      }}
    >
      <Grid
        item
        xs={1}
      />
      <Grid
        className={classes.privacyInnerContainer}
        item
        xs={10}
      >
        <div
          className={classes.privacyBackButton}
          onClick={() => handleChangePrivacyForm()}
        >
          <ArrowBackIcon />
          <Typography
            style={{
              marginLeft: '10px',
            }}
          >
            Back
          </Typography>
        </div>
        { mycarepathPrivacy() }
      </Grid>
    </Grid>
  );

  const signUpForm = (
    <div
      className={classes.formSignUp}
    >
      <Typography
        variant="h2"
        className={classes.formTitle}
      >
        Sign Up Now
      </Typography>
      <Typography
        className={classes.formSubtitle}
      >
        Are you looking for a new opportunity?
      </Typography>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <TextField
            size="small"
            placeholder="First Name"
            variant="outlined"
            onChange={(e) => changeFirstName(e.target.value)}
            value={firstName}
            InputProps={{
              style: {
                fontFamily: 'Lato',
              }
            }}
            style={{
              backgroundColor: '#fff',
              width: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
        >
          <TextField
            size="small"
            placeholder="Last Name"
            variant="outlined"
            onChange={(e) => changeLastName(e.target.value)}
            value={lastName}
            InputProps={{
              style: {
                fontFamily: 'Lato',
              }
            }}
            style={{
              backgroundColor: '#fff',
              width: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            size="small"
            placeholder="Email Address"
            variant="outlined"
            onChange={(e) => {
              changeEmailAddress(e.target.value);
              changeEmailValidation(false);
            }}
            value={emailAddress}
            label={emailValidation ? 'Please use a valid email' : null}
            error={emailValidation}
            InputProps={{
              style: {
                fontFamily: 'Lato',
              }
            }}
            style={{
              backgroundColor: '#fff',
              width: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            size="small"
            placeholder="Phone Number"
            variant="outlined"
            onChange={(e) => changePhoneNumber(e.target.value)}
            value={phoneNumber}
            InputProps={{
              style: {
                fontFamily: 'Lato',
              }
            }}
            style={{
              backgroundColor: '#fff',
              width: '100%',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <TextField
            size="small"
            placeholder="Were you referred by a TruPlay Employee?"
            variant="outlined"
            onChange={(e) => changeSelectedOption(e.target.value)}
            value={selectedOption}
            InputProps={{
              style: {
                fontFamily: 'Lato',
              }
            }}
            style={{
              backgroundColor: '#fff',
              width: '100%',
            }}
          />
          {/* <Select
            size="small"
            variant="outlined"
            onChange={(e) => changeSelectedOption(e.target.value)}
            value={selectedOption}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <span>Please select an option</span>;
              }

              return selected;
            }}
            classes={{
              select: styles.select,
            }}
            style={{
              display: 'block',
              backgroundColor: '#fff',
            }}
          >
            <MenuItem disabled value="" style={{ fontFamily: 'Lato'}}>
              Please select an option
            </MenuItem>
            <MenuItem
              value="I am interested in hiring Rutgers Alumni"
              onClick={() => handleOtherOptionClose()}
              style={{ fontFamily: 'Lato'}}
            >
              I am interested in hiring Rutgers Alumni
            </MenuItem>
            <MenuItem
              value="I am a Rutgers Alumni looking to hire other Rutgers Alumni"
              onClick={() => handleOtherOptionClose()}
              style={{ fontFamily: 'Lato'}}
            >
              I am a Rutgers Alumni looking to hire other Rutgers Alumni
            </MenuItem>
            <MenuItem
              value="Other (Please Share)"
              onClick={() => handleOtherOptionOpen()}
              style={{ fontFamily: 'Lato'}}
            >
              Other (Please Share)
            </MenuItem>
          </Select> */}
        </Grid>
        {/* <Grid
          item
          xs={12}
        >
          <TextField
            size="small"
            placeholder="Other Option"
            variant="outlined"
            onChange={(e) => changeOtherOptionField(e.target.value)}
            value={otherOptionField}
            InputProps={{
              style: {
                fontFamily: 'Lato',
              }
            }}
            style={{
              display: openOtherOption ? 'inline-flex' : 'none',
              backgroundColor: '#fff',
              width: '100%',
            }}
          />
        </Grid> */}
        <Grid
          item
          xs={12}
          className={classes.formSignUpButton}
        >
          <Button
            className={classes.buttonSkyBlueGradient}
            onClick={() => handleEmailValidation()}
            style={{
              fontFamily: 'Lato',
              fontWeight: '700',
              margin: '20px 0',
              minWidth: '200px',
              height: '44px',
            }}
          >
            Sign Up
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  const bodyText = (
    <Grid
      className="bodyContent"
      container
      style={{
        zIndex: '200',
      }}
    >
      <Grid
        container
      >
        <Grid
          item
          xs={12}
          className={classes.bodyTitleContainer}
        >
          <Typography
            variant="h2"
            className={classes.bodyTitle}
          >
            Ways the TruPlay Careers Platform Can Benefit You
          </Typography>
        </Grid>
        <Grid
          container
          style={{
            marginTop: '6.25rem',
            marginBottom: '3.125rem',
          }}
        >
          <Grid
            item
            xs={0}
            lg={2}
          />
          <Grid
            item
            container
            xs={12}
            lg={8}
          >
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/message-skyblue-icon.svg"
                  alt=""
                />
                <Typography
                  className={classes.cardDescription}
                >
                  Entertain conversations about potential career advancement without jeopardizing your current role
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/bag-skyblue-icon.svg"
                  alt=""
                />
                <Typography
                  className={classes.cardDescription}
                >
                  Sign up with TruPlay and our hiring team will reach out to you when suitable opportunities arise - no résumé required
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/collab-hands-skyblue-icon.svg"
                  alt=""
                />
                <Typography
                  className={classes.cardDescription}
                >
                  Anonymity in the platform will remove all implicit bias and discrimination in the hiring process
                </Typography>
              </div>
            </Grid>
            <Grid
              className={classes.squareContentContainer}
              item
              xs={12}
              md={6}
              lg={3}
            >
              <div
                className={classes.squareBorder}
              >
                <img
                  className={classes.squareIcon}
                  src="/icons/megaphone-skyblue-icon.svg"
                  alt=""
                />
                <Typography
                  className={classes.cardDescription}
                >
                  Stay informed and up to date with the latest information
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            item
            xs={2}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  const landingContent = (
    <>
      <Grid
        container
        className={clsx(classes.heroContainer, classes.heroBorders)}
        style={{
          overflow: 'hidden',
        }}
      >
        <Grid
          className={classes.heroImgContainer}
          item
          container
          xs={12}
        >
          <div
            style={{
              backgroundImage: `url(${contentHeroImg})`,
              position: 'absolute',
              zIndex: '1',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%',
              backgroundSize: 'cover',
              backgroundPosition: '25% 100%',
              overflowX: 'hidden',
            }}
          />
          <Grid
            item
            xs={0}
            sm={1}
            lg={2}
            className={classes.heroContainerItems}
          />
          <Grid
            item
            xs={12}
            sm={10}
            md={6}
            lg={4}
            className={clsx(classes.heroContainerItems, classes.heroContainerItemTitle)}
          >
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle1 }
            </Typography>
            <Typography
              variant="h2"
              className={classes.heroTitle}
            >
              { contentHeroTitle2 }
            </Typography>
            <Typography
              className={classes.heroDescription}
            >
              { contentHeroDescription }
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            lg={3}
            className={classes.formSignUpDesktop}
            style={{
              alignItems: 'flex-end',
              zIndex: '10',
            }}
          >
            { signUpForm }
          </Grid>
          <Grid
            ref={starsContainerRef}
            item
            xs={0}
            md={1}
            lg={2}
          />
        </Grid>
        <Box
          className={classes.ellipse}
          style={{
            zIndex: '100',
            height: `${ellipseHeight}px`,
            width: '100%',
            backgroundColor: '#f7f8f9',
            transform: `translateY(-${ellipseHeight / 2}px)`,
          }}
        />
        <img
          className={classes.imgStarsStyle}
          ref={imgStarsRef}
          style={{
            zIndex: '200',
            position: 'absolute',
            width: starPos.width,
            top: starPos.bottom,
            left: starPos.left,
          }}
          src="/images/hero-stars.svg"
          alt=""
        />
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.formSignUpMobile}
        style={{
          margin: '10px 0',
          alignItems: 'flex-end',
          zIndex: '200',
        }}
      >
        { signUpForm }
      </Grid>
      { bodyText }
      {/* <Grid
        container
        style={{
          padding: '2.5rem 0',
        }}
      >
        <Grid
          item
          xs={1}
          lg={2}
        />
        <Grid
          item
          xs={10}
          lg={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            className={classes.bodySubtitle}
          >
            Are you interested in hiring talent from our community?
          </Typography>
          <Button
            className={classes.buttonSkyBlueGradient}
            href={contentHeroButtonLink}
            target="_blank"
            rel="noreferrer"
            style={{
              margin: '20px 0',
              width: '200px',
              height: '44px',
            }}
          >
            Sign Up
          </Button>
        </Grid>
      </Grid> */}
    </>
  );

  return (
    <Grid
      container
      className={classes.contentContainer}
    >
      {
        privacyForm ? privacyContent : landingContent
      }
      <Popover
        open={confirmationForm}
        onClose={handleFormClose}
        classes={{
          paper: styles.popoverRoot,
        }}
        style={{
          overflowY: 'scroll',
          height: '100%',
          width: '100%',
          background: 'rgba(0,0,0,0.2)',
          boxShadow: 'none',
        }}
        disableScrollLock
      >
        <div
          className={classes.popupContainer}
        >
          <Typography
            variant="h2"
            className={classes.popupTitle}
          >
            Thank you for signing up to learn more about TruPlay Careers
          </Typography>
          <Typography
            style={{
              padding: '20px 0',
              fontSize: '1rem',
              fontFamily: 'Lato',
            }}
          >
            We will communicate next steps with you shortly
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              '@media (max-width: 601px)': {
                justifyContent: 'center'
              }
            }}
          >
            <Button
              className={classes.buttonSkyBlueGradient}
              onClick={() => handleFormClose()}
              style={{
                margin: '20px 0',
                minWidth: '200px',
                height: '44px',
              }}
            >
              Exit
            </Button>
          </div>
        </div>
      </Popover>
    </Grid>
  );
}

TemplateInfo.propTypes = {};

export default TemplateInfo;
