import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "../API";
import { Link } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarWithItems } from "Stories/Navigation.stories.js";
import { ReactComponent as FeedbackIcon } from "images/icons/Feedback.svg";
import BadIcon from "images/icons/feedback/Bad/On.svg";
import GreatIcon from "images/icons/feedback/Great/On.svg";
import OkIcon from "images/icons/feedback/Ok/On.svg";

class FeedbackScreen extends Component {
  state = {
    selectedIcon: "",
  };

  selectIcon = (iconName) => {
    if (iconName == this.state.selectedIcon) {
      this.setState({
        selectedIcon: "",
      });
    } else {
      this.setState({
        selectedIcon: iconName,
      });
    }
  };

  render() {
    return (
      <Container>
        <NavBarWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <ProfileCard {...this.props} />
          </Col>
          <Col>
            <SettingsContainer>
              <SettingsHeading>
                <div className="mr-2">
                  <FeedbackIcon />
                </div>
                <SettingsTitle>Feedback</SettingsTitle>
              </SettingsHeading>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                      <Form.Label>How was your experience?</Form.Label>
                      <FeedbackEmojisContainer>
                        <img
                          style={{
                            opacity:
                              this.state.selectedIcon === "great" ? 1 : 0.3,
                          }}
                          onClick={() => this.selectIcon("great")}
                          src={GreatIcon}
                        />
                        <img
                          style={{
                            opacity: this.state.selectedIcon === "ok" ? 1 : 0.3,
                          }}
                          onClick={() => this.selectIcon("ok")}
                          src={OkIcon}
                        />
                        <img
                          style={{
                            opacity:
                              this.state.selectedIcon === "bad" ? 1 : 0.3,
                          }}
                          onClick={() => this.selectIcon("bad")}
                          src={BadIcon}
                        />
                      </FeedbackEmojisContainer>
                      <Form.Control
                        className="p-3"
                        as="textarea"
                        rows="12"
                        style={{
                          backgroundColor: "#F5F9FB",
                        }}
                        placeholder="Describe your experience here..."
                      />
                    </Form.Group>
                  </Col>
                </Form.Row>
                <Form.Row className="mt-0">
                  <Col>
                    <Link to="/getstarted">
                      <Button variant="outline-primary float-right">
                        Send
                      </Button>
                    </Link>
                  </Col>
                </Form.Row>
              </Form>
            </SettingsContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

const SettingsContainer = styled.div`
  background: white;
  border: 3px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  padding: 40px;
`;

const FeedbackEmojisContainer = styled.div`
  img {
    margin: 10px;
    opacity: 0.3;
  }
  img:hover {
    opacity: 1;
    cursor: pointer;
  }
`;
const SettingsHeading = styled.div`
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
`;
const SettingsTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

export default FeedbackScreen;
