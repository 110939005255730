import React, { Component, useEffect } from "react";
import { Container, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MobileOnlyView, BrowserView } from "react-device-detect";
import CloseIcon from '@material-ui/icons/Close';
import { ProfileCard } from "Stories/ProfileCard.stories";
import "rc-slider/assets/index.css";
import { NavBarWithItems } from "Stories/Navigation.stories.js";
import styled from "styled-components";
import PersonalitySnapshot from "PersonalitySnapshot";
import CandidateOnboarding from "CandidateOnboarding";
import UserFeed from "UserFeed";
import API from "../API";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import BottomNavigation from "BottomNavigation";
import LoopIcon from '@material-ui/icons/Loop';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const SliderWithTooltip = createSliderWithTooltip(Slider);

let interval;

class HomeScreen extends Component {
  state = {
    update: 0,
    showPersonality: false,
    candidateMatches: [],
    modal: {
      viewableMatch: {
        recruiter: {
          firstName: "",
        },
        searchQuery: {
          hiringCompany: "",
        },
      },
      show: "skills || noSkills",
      close: false,
      validated: false,
      hasSkills: false,
      skills: [],
    },
  };

  async componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter={
        "include": {"candidateMatches": ["searchQuery", "candidate", "recruiter", "skills"] }
      }`
    ).then((response) => {
      this.setState(response.data);

      if (
        this.state.candidateMatches.filter(
          (el) => el.candidateStatus === "pending"
        ).length > 0
      ) {
        let pendingMatches = this.state.candidateMatches.filter(
          (el) => el.candidateStatus === "pending"
        );
        this.viewMatch(pendingMatches[0].id);
      }
    });

    // window.OneSignal.getUserId(function (id) {
    //   console.log("PLAYER ID", id);
    //   API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
    //     pushId: id,
    //   }).then((response) => {
    //     console.log("Updated Push ID");
    //   });
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(prevState.modal)
    // console.log(this.state.modal)
    // console.log(`${prevState.modal !== this.state.modal} ${!this.state.modal.validated}`)
    // if (prevState.modal !== this.state.modal && !this.state.modal.validated) {
    //   this.handleCheckFormValidation()
    // }
  }

  componentWillUnmount() {
    clearInterval(interval);
  }

  viewMatch = (matchId) => {
    let match = this.state.candidateMatches.find(
      (candidateMatch) => candidateMatch.id === matchId
    );

    let skills = [];

    if (match.searchQuery.hasOwnProperty("skills")) {
      skills = match.searchQuery.skills;
    }
    skills = skills.map((skill, index) => ({
      experienceMin: 0,
      experienceMax: 0,
      skillName: skill,
      id: index,
    }));

    // console.log({ skills })
    // console.log("skills validation", Object.keys(skills?.[0]).length)
    if (skills.length > 0 && Object.keys(skills?.[0]).length > 0) {
      this.setState({
        modal: {
          ...this.state.modal,
          viewableMatch: match,
          skills: skills,
          show: "skills",
        },
      });
    } else {
      this.setState({
        modal: {
          ...this.state.modal,
          viewableMatch: match,
          skills: skills,
          show: "noSkills",
        },
      });
    }
  };

  handleCheckFormValidation = async () => {
    const regex = /https:\/\/truplaygames\.bamboohr\.com\/careers\/([\d]+)/;
    const jobApplicationId = this.state.modal.viewableMatch.searchQuery.bambooFormLink?.match(regex)[1];
    const email = this.state.email;
    const searchValidations = await API.get(`/BambooFormValidations`)
      .then((res) => {
        return res.data;
      })
    const searchExists = searchValidations?.some((item) => item.email === email && item.jobApplicationId == jobApplicationId && item.submissionCompleted === true);
    // console.log('Starts Validation...')
    // console.log({ jobApplicationId, email })
    // console.log((this.state.modal.show === "noSkills" || this.state.modal.show === "skills") && !this.state.modal.close && !searchExists)
    if ((this.state.modal.show === "noSkills" || this.state.modal.show === "skills") && !this.state.modal.close && !searchExists) {
      interval = setInterval(() => {
        API.post(`/BambooFormValidations`, {
          email,
          jobApplicationId,
        }).then((res) => {
          // console.log('closing interval!')
          const completed = res.data.submissionCompleted;
          // console.log('completed', completed)
          if (completed) {
            this.setState({
              modal: {
                ...this.state.modal,
                validated: true,
              }
            });
            clearInterval(interval);
          }
        });
      }, 10000);
    }
    
    if (searchExists) {
      this.setState({
        modal: {
          ...this.state.modal,
          validated: true,
        }
      })
    }
  }

  handleMatchesModalClose = () => {
    clearInterval(interval);
    this.setState({
      modal: {
        ...this.state.modal,
        close: true
      }
    });
  }

  handleAcceptMatch = (id) => {
    API.patch(`/UserMatches/${id}`, {
      candidateStatus: "accepted",
      candidateVeil: false,
    }).then((response) => {
      API.get(
        `/UserProfiles/${this.props.userToken.userId}?filter={
          "include": {"candidateMatches": ["recruiter", "searchQuery"] }
        }`
      ).then((response) => {
        this.setState(response.data);
      });
    });
  };

  onExperienceSliderChange = (sliderId, sliderValue) => {
    let selectedSkills = this.state.modal.skills;
    let updatedSkills = selectedSkills.map((skill) => {
      if (sliderId === skill.id) {
        skill.experienceMin = sliderValue;
      }
      return skill;
    });

    this.setState({
      modal: {
        skills: updatedSkills,
        ...this.state.modal,
      },
    });
  };

  handleAccept = () => {
    let recruiterSearchQueryEvaluation = this.state.modal.skills.map(
      (skill) => {
        API.post(`/RecruiterSearchQuerySkillsEvaluators`, {
          experienceMax: skill.experienceMax,
          experienceMin: skill.experienceMin,
          skillName: skill.skillName,
          candidateId: this.state.modal.viewableMatch.candidate.id,
          recruiterId: this.state.modal.viewableMatch.recruiter.id,
          matchId: this.state.modal.viewableMatch.id,
        }).then((r) => {
          this.props.history.push("/matches");
        });
      }
    );

    Promise.all(recruiterSearchQueryEvaluation)
      .then((response) => {
        return API.patch(`/UserMatches/${this.state.modal.viewableMatch.id}`, {
          candidateStatus: "accepted",
          candidateVeil: false,
          candidateId: this.state.modal.viewableMatch.candidate.id,
          recruiterId: this.state.modal.viewableMatch.recruiter.id,
          searchQueryTitle:
            this.state.modal.viewableMatch.searchQuery.searchTitle,
          recruiterEmail: this.state.modal.viewableMatch.recruiter.email,
        });
      })
      .then((response) => {
        return API.get(
          `/UserProfiles/${this.props.userToken.userId}?filter={
            "include": {"candidateMatches": ["recruiter", "searchQuery"] }
          }`
        );
      })
      .then((response) => {
        this.setState(response.data);
        this.handleClose();
      });
  };

  handleDecline = () => {
    API.delete(`/UserMatches/${this.state.modal.viewableMatch.id}`, {
      candidateStatus: "declined",
      candidateId: this.state.modal.viewableMatch.candidate.id,
      recruiterId: this.state.modal.viewableMatch.recruiter.id,
      searchQueryTitle: this.state.modal.viewableMatch.searchQuery.searchTitle,
      recruiterEmail: this.state.modal.viewableMatch.recruiter.email,
    }).then((response) => {
      API.get(
        `/UserProfiles/${this.props.userToken.userId}?filter={
          "include": {"candidateMatches": ["recruiter", "searchQuery"] }
        }`
      ).then((response) => {
        this.setState(response.data);
        this.handleClose();
      });
    });
  };

  handleClose = () => {
    this.setState({
      modal: {
        viewableMatch: {
          recruiter: {
            firstName: "",
          },
          searchQuery: {
            hiringCompany: "",
          },
        },
        show: "skills | noSkills",
        hasSkills: false,
        skills: [],
      },
    });
  };

  updateUpdate = () => {
    this.setState({
      update: this.state.update + 1,
    });
  };

  updateShowPersonality = () => {
    this.setState({
      showPersonality: !this.state.showPersonality,
    });
  };

  handleValidated = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        validated: true,
      }
    })
  }

  render() {
    return (
      <Container
        style={{
          maxWidth: 1325,
        }}
      >
        <NavBarWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <ProfileCard {...this.props} />
          </Col>
          <Col>
            <div
              style={{
                marginTop: 20,
              }}
            ></div>
            <UserFeed
              {...this.props}
              profileComplete={this.state.profileComplete}
              forcedUpdate={this.state.update}
            />
          </Col>
          <Col md={3}>
            <PersonalitySnapshot
              {...this.props}
              forcedUpdate={this.state.update}
            />
          </Col>
        </Row>
        <CandidateOnboarding
          style={{
            zIndex: 100,
          }}
          {...this.props}
          updateUpdate={this.updateUpdate}
          updateShowPersonality={this.updateShowPersonality}
        />
        <Modal
          show={this.state.modal.show === "skills" && !this.state.modal.close}
          onHide={() => this.handleMatchesModalClose()}
          size="xl"
          centered
        >
          <ModalContainerTwo>
            <BrowserView>
              <ModalCol>
                <img
                  style={{
                    width: 280,
                    bottom: 0,
                  }}
                  src={require("images/modals/man-thinking-tall.png")}
                />
              </ModalCol>
            </BrowserView>
            <ModalCol className="p-5 w-100 bg-white">
              <MatchesIconContainer onClick={() => this.handleMatchesModalClose()}>
                <CloseIcon
                  style={{
                    fontSize: '25px',
                  }}
                />
              </MatchesIconContainer>
              <p
                style={{
                  fontSize: 24,
                  marginBottom: 0,
                  color: "#002952",
                }}
              >
                New Match from{" "}
                {this.state.modal.viewableMatch.searchQuery.hiringCompany}
              </p>
              <p style={{ fontSize: 14, marginTop: 0 }}>
                {this.state.modal.viewableMatch.recruiter.firstName} hiring on
                behalf of{" "}
                {this.state.modal.viewableMatch.searchQuery.hiringCompany} wants
                to talk but first, they need to know how well versed you are
                in....
              </p>
              {this.state.modal.skills.map((skill, index) => {
                if (!(skill.skillName.length > 0)) return null;
                return (
                <Form>
                  <Form.Row
                    style={{
                      paddingTop: index !== 0 ? 30 : 0,
                      paddingLeft: 30,
                      paddingRight: 30,
                    }}
                    className="mt-5"
                  >
                    <Col>
                      <Form.Label>{skill.skillName}</Form.Label>
                      <SliderWithTooltip
                        className="mt-3"
                        min={0}
                        tipFormatter={(value) => "OK"}
                        max={4}
                        tipProps={{ visible: true }}
                        onChange={(d) =>
                          this.onExperienceSliderChange(skill.id, d)
                        }
                        marks={{
                          0: "Don't Know This Skill",
                          1: "Beginner",
                          2: "Intermediate",
                          3: "Advanced",
                          4: "World Class",
                        }}
                        railStyle={{
                          backgroundColor: "#f5f5f5",
                          height: 10,
                        }}
                        trackStyle={[
                          { background: "linear-gradient(90deg, rgba(56,166,213,1) 0%, rgba(122,201,197,1) 100%)", height: 10 },
                          { background: "linear-gradient(90deg, rgba(56,166,213,1) 0%, rgba(122,201,197,1) 100%)", height: 10 },
                        ]}
                        handleStyle={[
                          {
                            background: "linear-gradient(90deg, rgba(56,166,213,1) 0%, rgba(122,201,197,1) 100%)",
                            borderColor: "rgba(122,201,197,1)",
                            width: 17,
                            height: 17,
                            top: 6,
                          },
                          {
                            background: "linear-gradient(90deg, rgba(56,166,213,1) 0%, rgba(122,201,197,1) 100%)",
                            borderColor: "rgba(122,201,197,1)",
                            width: 17,
                            height: 17,
                            top: 6,
                          },
                        ]}
                        dotStyle={{
                          display: "none",
                        }}
                      />
                    </Col>
                  </Form.Row>
                </Form>
              )})}
              <Form.Row className="mt-5">
                <Col className="">
                  <div></div>
                  <div className="d-flex justify-content-center mt-3">
                    {/* <Button onClick={this.handleAccept} className="mt-3">
                      ACCEPT and Reveal my Identity
                    </Button> */}
                    {
                      this.state.modal.validated ? (
                        <Button onClick={this.handleAccept} className="mt-3">
                          ACCEPT and Reveal my Identity
                        </Button>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <a
                            href={this.state.modal.viewableMatch.searchQuery.bambooFormLink}
                            target="_blank"
                            onClick={() => this.handleValidated()}
                          >
                            <Button
                              className="mt-3 verifyButton"
                            >
                              Before Accepting Invite,<br />
                              Please Complete BambooHR Form
                            </Button>
                          </a>
                          <span
                            style={{
                              color: '#999999',
                              fontSize: '14px',
                            }}
                          >
                            Verifying...&nbsp;
                            <LoopIcon
                              className="rotate"
                              style={{
                                fontSize: '20px',
                              }}
                            />
                          </span>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex justify-content-center">
                    <p
                      style={{
                        color: "#002952",
                        fonSize: 12,
                        textDecoration: "underline",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                      onClick={this.handleDecline}
                      className="mt-3"
                      variant="outline-primary"
                    >
                      Decline and Do Not Reveal my Identity
                    </p>
                  </div>
                </Col>
              </Form.Row>
            </ModalCol>
          </ModalContainerTwo>
        </Modal>
        <Modal
          show={this.state.modal.show === "noSkills" && !this.state.modal.close}
          onHide={() => this.handleMatchesModalClose()}
          size="lg"
          centered
        >
          <ModalContainerTwo>
            <ModalCol>
              <img
                className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block"
                style={{
                  width: 280,
                  bottom: 0,
                }}
                src={require("images/modals/man-thinking-tall.png")}
              />
            </ModalCol>

            <ModalCol className="p-5 w-100 bg-white">
              <MatchesIconContainer onClick={() => this.handleMatchesModalClose()}>
                <CloseIcon
                  style={{
                    fontSize: '25px',
                  }}
                />
              </MatchesIconContainer>
              <h1>
                New Match from{" "}
                {this.state.modal.viewableMatch.searchQuery.hiringCompany}
              </h1>
              <p>
                {this.state.modal.viewableMatch.recruiter.firstName} hiring on
                behalf of{" "}
                {this.state.modal.viewableMatch.searchQuery.hiringCompany} wants
                to talk but first, they need to know how well versed you are
                in....
              </p>
              <Form.Row className="mt-5">
                <Col className="">
                  <div className="d-flex justify-content-center">
                    {/* <Button onClick={this.handleAccept} className="mt-3">
                      ACCEPT and Reveal my Identity
                    </Button> */}
                    {
                      this.state.modal.validated ? (
                        <Button
                          onClick={this.handleAccept}
                          className="mt-3"
                        >
                          ACCEPT and Reveal my Identity
                        </Button>
                      ) : (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <a
                            href={this.state.modal.viewableMatch.searchQuery.bambooFormLink}
                            target="_blank"
                            onClick={() => this.handleValidated()}
                          >
                            <Button
                              className="mt-3 verifyButton"
                            >
                              Before Accepting Invite,<br />
                              Please Complete BambooHR Form
                            </Button>
                          </a>
                          <span
                            style={{
                              color: '#999999',
                              fontSize: '14px',
                            }}
                          >
                            Verifying...&nbsp;
                            <LoopIcon
                              className="rotate"
                              style={{
                                fontSize: '20px',
                              }}
                            />
                          </span>
                        </div>
                      )
                    }
                  </div>
                  <div className="d-flex justify-content-center">
                    <p
                      style={{
                        color: "#002952",
                        fonSize: 12,
                        textDecoration: "underline",
                        fontWeight: "bold",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={this.handleDecline}
                      className="mt-3"
                      variant="outline-primary"
                    >
                      Decline and Do Not Reveal my Identity
                    </p>
                  </div>
                </Col>
              </Form.Row>
            </ModalCol>
          </ModalContainerTwo>
        </Modal>
        <BottomNavigation />
      </Container>
    );
  }
}

const MatchesContainer = styled.div`
  background: white;
  border: 1px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  min-height: 400px;
`;

const MatchesHeadingContainer = styled.div`
  flex: 1;
  display: flex;
  margin: 20px;
  border-bottom: 1px solid #f5f5f5;
`;

const MatchesHeadingMenuItem = styled.p`
  margin-right: 20px;
  color: #002952;
  font-size: 14px;
`;

const MatchesListContainer = styled.div``;
const MatchesListItemContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  flex: 5;
  align-items: center;
  padding: 10px;
  margin: 20px;
`;
const MatchesItemImage = styled.img``;
const MatchesItemDetailsContainer = styled.div`
  margin-left: 20px;
  flex: 3;
`;
const MatchesItemName = styled.p`
  margin: 0;
  font-weight: bold;
  color: #000;
  font-size: 16px;
`;
const MatchesItemJobTitle = styled.p`
  color: #002952;
  font-size: 14px;
  margin: 0;
`;
const MatchesItemLocation = styled.p`
  font-size: 14px;
  margin: 0;
  color: silver;
`;

const NullMatchesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  text-align: center;
  h1 {
    color: #999 !important;
  }
  p {
    color: #999;
    font-size: 16px;
  }
`;

const MatchesIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #000;
  :hover {
    color: rgba(0,0,0,0.6);
  }
`

const ModalContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalCol = styled.div`
  background-color: #edeff0;
`;

export default HomeScreen;
