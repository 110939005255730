import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import useScriptGA from './utils/useScriptGA';
import data from './constants/content';
import RootLandingTemplate from './components/RootLandingTemplate';
import TemplateFooter from './components/TemplateFooter';

function LandingRoot(props) {
  // useScriptGA();
  return (
    <>
      <RootLandingTemplate
        {...data}
      />
      <TemplateFooter
        {...data}
      />
    </>
  )
}

LandingRoot.propTypes = {}

export default LandingRoot
