import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Navbar,
  Nav,
  Modal,
  Button,
  Form,
  Badge,
  Pill,
} from "react-bootstrap";
import { ProfileCard } from "Stories/ProfileCard.stories";
import API from "../API";
import { Link } from "react-router-dom";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";
import { NavBarWithItems } from "Stories/Navigation.stories.js";
import { Avatar } from "Stories/Avatars.stories.js";
import axios from "axios";

class Jobs extends Component {
  state = {
    zip: {
      jobs: [],
    },
    userDesiredJobPositionSpecialties: [],
    desiredCities: [],
  };

  componentDidMount() {
    API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) => {
      this.setState(response.data);
      let allRequests = this.state.desiredCities.map((city) => {
        return axios.get(
          `
    https://api.ziprecruiter.com/jobs/v1?search=${this.state.userDesiredJobPositionSpecialties[0].jobPositionSpecialty.name}&location=${city.value}&radius_miles=25&days_ago=&jobs_per_page=500&page=1&api_key=i8iap6xx2rpn3s2epnj7vyw7p6uvyn3v
    `
        );
      });

      Promise.all(allRequests).then((all) => {
        let jobs = all.map((r) => r.data.jobs);
        let mergedJobs = [].concat.apply([], jobs);
        this.setState({
          zip: {
            jobs: mergedJobs,
          },
        });
      });
    });
  }

  render() {
    return (
      <Container>
        <NavBarWithItems {...this.props} />
        <Row>
          <Col md={3}>
            <ProfileCard {...this.props} />
          </Col>
          <Col>
            <JobsContainer>
              <JobsHeading>TruPlay Careers Hiring Partners</JobsHeading>
              <JobsHeadingDetail>
                The below job listings are based on your profile desired
                position types and locations that you input into your profile
                from TruPlay's hiring partners. If you want to see jobs in
                different areas, please go update your profile.
              </JobsHeadingDetail>
              <JobsHeadingDetail>
                These are not affilitated with actual TruPlay Recruiters, but
                serve as an add-on feature to help accelerate your job search
                since you indicated you were actively looking at signup.
              </JobsHeadingDetail>

              <JobsList>
                {this.state.zip.jobs.map((job) => (
                  <JobItemContainer>
                    <JobItemImage src={require("images/thirdpartyjobs.svg")} />
                    <JobTitle>{job.name}</JobTitle>
                    <div
                      style={{
                        height: 70,
                      }}
                    >
                      <JobCompany>{job.hiring_company.name}</JobCompany>
                      <JobLocation>
                        {job.city}, {job.state}
                      </JobLocation>
                    </div>
                    {/* <JobSalary>$100,000</JobSalary> */}
                    <a href={job.url} target="_blank">
                      <JobLinkContainer>
                        <JobLinkText>Apply</JobLinkText>
                      </JobLinkContainer>
                    </a>
                  </JobItemContainer>
                ))}
              </JobsList>
            </JobsContainer>
          </Col>
        </Row>
      </Container>
    );
  }
}

const JobsContainer = styled.div`
  background: white;
  border: 1px solid #f5f5f5;
  flex: 1;
  margin-top: 20px;
  min-height: 400px;
  padding: 40px;
`;

const JobsHeadingContainer = styled.div`
  flex: 1;
  display: flex;
  margin: 20px;
  border-bottom: 1px solid #f5f5f5;
`;

const JobsHeadingMenuItem = styled.p`
  margin-right: 20px;
  color: #002952;
  font-size: 14px;
`;

const JobsListContainer = styled.div``;
const JobsListItemContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  flex: 5;
  align-items: center;
  padding: 10px;
  margin: 20px;
`;
const JobsItemImage = styled.img``;
const JobsItemDetailsContainer = styled.div`
  margin-left: 20px;
  flex: 3;
`;
const JobsItemName = styled.p`
  margin: 0;
  font-weight: bold;
  color: #000;
  font-size: 16px;
`;
const JobsItemJobTitle = styled.p`
  color: #002952;
  font-size: 14px;
  margin: 0;
`;
const JobsItemLocation = styled.p`
  font-size: 14px;
  margin: 0;
  color: silver;
`;

const NullJobsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px;
  text-align: center;
  h1 {
    color: #999 !important;
  }
  p {
    color: #999;
    font-size: 16px;
  }
`;

const ModalContainerTwo = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalCol = styled.div`
  background-color: #edeff0;
`;

const JobsHeading = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #000;
`;

const JobsHeadingDetail = styled.p`
  font-size: 14px;
  color: #999;
`;

const JobsList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const JobItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid #f5f5f5;
  padding: 20px;
  justify-content: center;
  width: 220px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const JobItemImage = styled.img`
  width: 60px;
  height: 60px;
`;
const JobTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
`;
const JobCompany = styled.p`
  font-size: 14px;
  margin: 0;

  text-align: center;
`;
const JobLocation = styled.p`
  font-size: 14px;
  margin: 0;
  margin-top: 3px;
  text-align: center;
`;
const JobSalary = styled.p`
  font-size: 14px;
  margin: 0;
`;
const JobLinkContainer = styled.div`
  background-color: #002952 !important;
  border-radius: 20px;
  padding-left: 40px;
  padding-right: 40px;
`;
const JobLinkText = styled.p`
  font-size: 16px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 5px;
`;

export default Jobs;
