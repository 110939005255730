import React, { Component } from "react";
import API from "../API";
import styled from "styled-components";
import { Row, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as SearchIcon } from "images/icons/Search.svg";
import { ReactComponent as SpeakerIcon } from "images/icons/Feedback.svg";
import { ReactComponent as NotificationIcon } from "images/icons/Notifications.svg";
import { BrowserView, MobileOnlyView } from "react-device-detect";
import Dropzone from "react-dropzone";
import axios from "axios";
import { Avatar } from "Stories/Avatars.stories.js";
import moment from "moment";

class UserFeed extends Component {
  state = {
    feed: [],
    profileComplete: false,
    profilePhoto: "",
    message: "",
  };

  componentDidMount() {
    API.get(`/UserFeeds?filter={"order": "createdAt DESC"}`).then(
      (response) => {
        console.log("Feed Data: ", response.data);
        this.setState({
          feed: response.data,
        });
      }
    );

    // var myInt = setInterval(() => {
    //   API.get(`/UserProfiles/${this.props.userToken.userId}`).then(response => {
    //     this.setState({
    //       profileComplete: response.data.profileComplete
    //     });
    //   });
    // }, 2000);
    // this.setState({
    //   myInt: myInt
    // });
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  uploadFile = (acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      let formData = new FormData();
      formData.append("newFormData", file);
      axios
        .post(
          "https://my.gogig.com:4000/api/Attachments/gogig-v3/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          API.patch(`/UserProfiles/${this.props.userToken.userId}`, {
            profilePhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
          this.setState({
            profilePhoto:
              "https://my.gogig.com:4000/api/Attachments/gogig-v3/download/" +
              response.data.result.files.newFormData[0].name,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    });
  };

  componentDidUpdate() {
    // API.get(`/UserProfiles/${this.props.userToken.userId}`).then((response) => {
    //   this.setState({
    //     feed: response.data,
    //     profileComplete: response.data.profileComplete,
    //   });
    // });
  }

  handleSubmit = () => {
    API.post(`/UserFeeds`, {
      message: this.state.message,
      attachment: this.state.profilePhoto,
      userId: this.props.userToken.userId,
    }).then(() => {
      this.setState({
        message: "",
        profilePhoto: "",
      });
      API.get(`/UserFeeds?filter={"order": "createdAt DESC"}`).then(
        (response) => {
          this.setState({
            feed: response.data,
          });
        }
      );
    });
  };

  componentWillUnmount() {
    clearInterval(this.state.myInt);
  }

  renderStatusMessage() {
    return this.props.profileComplete ? (
      <CompleteProfileCard>
        <CompleteProfileCardTitleContainer>
          <SpeakerIcon />
          <CompleteProfileCardTitle>You're all set!</CompleteProfileCardTitle>
        </CompleteProfileCardTitleContainer>
        <CompleteProfileCardDescription>
          You've successfully completed your profile! Review your full Candidate Profile to learn more about how hiring managers will see you.
        </CompleteProfileCardDescription>
        <Row>
          <Col>
            <Link to="/profile">
              <Button className="float-right">View Profile</Button>
            </Link>
          </Col>
        </Row>
      </CompleteProfileCard>
    ) : (
      <CompleteProfileCard>
        <CompleteProfileCardTitleContainer>
          <SearchIcon />

          <CompleteProfileCardTitle>
            Your profile is not discoverable to Recruiters within TruPlay Careers
          </CompleteProfileCardTitle>
        </CompleteProfileCardTitleContainer>
        <CompleteProfileCardDescription>
          Your profile is not complete. In order for you to become discoverable
          to Hiring Companies in TruPlay Careers and view your Communication Style Report, you
          must complete your profile.
        </CompleteProfileCardDescription>
        <Link to="/profile?onboard=true">
          <Button
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: 0,
            }}
          >
            Complete Profile
          </Button>
        </Link>
      </CompleteProfileCard>
    );
  }

  render() {
    return (
      <div>
        {!this.props.recruiterMode && this.renderStatusMessage()}

        <CompleteProfileCard style={{ marginTop: 20 }}>
          <CompleteProfileCardTitleContainer>
            <NotificationIcon />
            <CompleteProfileCardTitle>
              Enable Notifications
            </CompleteProfileCardTitle>
          </CompleteProfileCardTitleContainer>
          <CompleteProfileCardDescription>
           We don't communicate to you often, but when we do it is of value based on your desires for career advancement 
          </CompleteProfileCardDescription>
          <Row>
            <Col>
              <Link to="/settings/notifications">
                <Button className="float-right">Go To Notifications</Button>
              </Link>
            </Col>
          </Row>
        </CompleteProfileCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/tp-logo.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>TruPlay Careers</NewsFeedCardName>
              <NewsFeedCardJob>Welcome!</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 05 at 9:20 AM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              <br />
              Welcome to the TruPlay Careers Portal!
              <br />
              <br />
              We're excited about the upcoming launch of our multimedia mobile gaming platform, and we are currently creating a range of exciting career opportunities that we'd love for you to explore here, on our new TruPlay Talent Community.
              <br /><br />
              Most of our current employees  were searching for something more when they found TruPlay. It’s not everyday that you find a Company where your career provides meaning and purpose. Where you can make a real difference in the world. If this sounds like you, then you've come to the right place!
              <br />
              <br />
              Our portal allows professionals like you to register anonymously to be visible to be considered for any <strong>future</strong> positions that become available. You can feel comfortable knowing that your identity will remain anonymous until you feel comfortable revealing it. We hope you find our new portal enjoyable to navigate and that you'll take advantage of this opportunity to join our team.
              <br />
              <br />
              As we build this community overtime, we recommend you check back in once a month to explore new opportunities and talent.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard>

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/tp-logo.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Hank Stringer</NewsFeedCardName>
              <NewsFeedCardJob>Head of Recruiting</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>February 13 at 4:00 PM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Welcome to TruPlay Games Careers!
              <br />
              <br />
              <strong>Current Career Opportunities:</strong> Positions we
              are recruiting to fill TODAY
              <br />
              <strong>Future Career Opportunities:</strong> Positions we
              expect to fill at some point in the FUTURE
              <br />
              <br />
              If all continues to go well at TruPlay Games (and it will!)
              and you would like to be immediately considered for your roles
              of choice (limit of 3) when we decide to hire, please make sure
              your profile is up to date with your game industry expertise and
              desires today.
              <br />
              <br />
              This career transition process is different then traditional
              career sites based on positions to be filled at the moment. We
              believe it makes sense to plan ahead for US and for YOU!
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}

        {/* {this.props.location.pathname !== "/" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              position: "relative",
              alignItems: "flex-start",
              flexDirection: "column",
              background: "white",
              marginBottom: 20,
              padding: 15,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  flex: "1",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <textarea
                  onChange={this.handleChange}
                  value={this.state.message}
                  name="message"
                  placeholder="What would you like to post?"
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                    resize: "none",
                  }}
                ></textarea>
              </div>
              {this.state.profilePhoto === "" ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "100px",
                    alignItems: "flex-start",
                    flex: "0 0 auto",
                  }}
                >
                  <Dropzone onDrop={this.uploadFile}>
                    {({ getRootProps, getInputProps }) => (
                      <StyledSection>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <p style={{ margin: 0 }} className="text-muted">
                            Add Attachment
                          </p>
                        </div>
                      </StyledSection>
                    )}
                  </Dropzone>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    height: "auto",
                    alignItems: "flex-start",
                    flex: "0 0 auto",
                  }}
                >
                  <div>
                    <p
                      onClick={() => this.setState({ profilePhoto: "" })}
                      style={{ textAlign: "right", cursor: "pointer" }}
                    >
                      Remove
                    </p>
                  </div>
                  <ProfilePhoto src={this.state.profilePhoto} />
                </div>
              )}

              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  width: "100%",
                  alignItems: "flex-end",
                  flex: "0 0 auto",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button onClick={this.handleSubmit} className="float-right">
                  Post
                </Button>
              </div>
            </div>
          </div>
        )} */}

        {this.state.feed.map((feedItem) => {
          return feedItem.attachment === "" ? (
            <NewsFeedCard>
              <NewsFeedCardHeadingContainer>
                <Avatar
                  style={{
                    marginRight: 10,
                  }}
                  width={50}
                  height={50}
                  character={feedItem.user.avatar.slug}
                />
                <NewsFeedCardDetailsContainer>
                  <NewsFeedCardName>
                    {feedItem.user.firstName + " " + feedItem.user.lastName}
                  </NewsFeedCardName>
                  <NewsFeedCardJob>
                    {feedItem.user.hasOwnProperty("recruiterProfile")
                      ? feedItem.user.recruiterProfile.jobTitle +
                        " at " +
                        feedItem.user.recruiterProfile.companyName
                      : "Professional Recruiter"}
                  </NewsFeedCardJob>
                </NewsFeedCardDetailsContainer>
                <NewsFeedCardTime>
                  {moment(feedItem.createdAt).fromNow()}
                </NewsFeedCardTime>{" "}
              </NewsFeedCardHeadingContainer>
              <NewsFeedCardDescriptionContainer>
                <NewsFeedCardDescription>
                  {feedItem.message}
                </NewsFeedCardDescription>
              </NewsFeedCardDescriptionContainer>
            </NewsFeedCard>
          ) : (
            <NewsFeedCard>
              <NewsFeedCardHeadingContainer>
                <Avatar
                  style={{
                    marginRight: 10,
                  }}
                  width={50}
                  height={50}
                  character={feedItem.user.avatar.slug}
                />
                <NewsFeedCardDetailsContainer>
                  <NewsFeedCardName>
                    {" "}
                    {feedItem.user.firstName + " " + feedItem.user.lastName}
                  </NewsFeedCardName>
                  <NewsFeedCardJob>
                    {feedItem.user.hasOwnProperty("recruiterProfile")
                      ? feedItem.user.recruiterProfile.jobTitle +
                        " at " +
                        feedItem.user.recruiterProfile.companyName
                      : "Professional Recruiter"}
                  </NewsFeedCardJob>
                </NewsFeedCardDetailsContainer>
                <NewsFeedCardTime>
                  {moment(feedItem.createdAt).fromNow()}
                </NewsFeedCardTime>
              </NewsFeedCardHeadingContainer>
              <NewsFeedCardDescriptionContainer>
                <NewsFeedCardDescription>
                  {feedItem.message}
                </NewsFeedCardDescription>
              </NewsFeedCardDescriptionContainer>
              <NewsFeedVideoContainer>
                <img
                  style={{
                    width: "100%",
                  }}
                  src={feedItem.attachment}
                />
              </NewsFeedVideoContainer>
            </NewsFeedCard>
          );
        })}


        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/tp-logo.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Hank Stringer</NewsFeedCardName>
              <NewsFeedCardJob>Head of Recruiting</NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>February 13 at 4:00 PM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Welcome to TruPlay Games Careers!
              <br />
              <br />
              <strong>Current Career Opportunities:</strong> Positions we
              are recruiting to fill TODAY
              <br />
              <strong>Future Career Opportunities:</strong> Positions we
              expect to fill at some point in the FUTURE
              <br />
              <br />
              If all continues to go well at TruPlay Games (and it will!)
              and you would like to be immediately considered for your roles
              of choice (limit of 3) when we decide to hire, please your
              profile is up to date with your game industry expertise and
              desires today.
              <br />
              <br />
              This career transition process is different then traditional
              career sites based on positions to be filled at the moment. We
              believe it makes sense to plan ahead for US and for YOU!
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
        </NewsFeedCard> */}

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/icons/Avatar/Tiger.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at GoGig
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 8 at 7:14 PM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>

          <a
            style={{
              textDecoration: "none",
              color: "#000",
            }}
            href="https://www.gogig.com/blog/"
            target="_blank"
          >
            <NewsFeedBlogContainer>
              <NewsFeedBlogImg src="https://miraclehub.co/wp-content/uploads/2021/08/Untitled-design-2-2.png" />
              <NewsFeedBlogMetaContainer>
                <NewsFeedBlogHeadline>
                  How 2020 Is Reshaping the Way We Find Jobs
                </NewsFeedBlogHeadline>
                <NewsFeedBlogDescription>
                  Whether there’s a lull in your particular job market or bias
                  within the workforce, there’s a long list of reasons you may
                  be having trouble finding your ideal position. Here are four
                  big factors that may be stopping you from landing the right
                  opportunity in 2020
                </NewsFeedBlogDescription>
              </NewsFeedBlogMetaContainer>
            </NewsFeedBlogContainer>
          </a>
        </NewsFeedCard> */}

        {/* <BrowserView>
          <NewsFeedCard>
            <BadgesContainer>
              <GoGigLogo src={require("images/logo/GoGig.png")} />
              <DownloadOnGoGig
                style={{
                  marginLeft: 30,
                  flexGrow: 1,
                }}
              >
                Download GoGig
              </DownloadOnGoGig>
              <div>
                <GetOnApple src="https://selinko.com/site/wp-content/uploads/2017/10/itunes-app-store-logo-300x104.png" />
                <GetOnGoogle src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" />
              </div>
            </BadgesContainer>
          </NewsFeedCard>
        </BrowserView> */}

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage src={require("images/icons/Avatar/tp-logo.png")} />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>TruPlay Careers</NewsFeedCardName>
              <NewsFeedCardJob>
                TruPlay Mission
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>June 02 at 4:00 PM</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            {/* <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription> */}
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/our-mission.mp4" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        {/* <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at GoGig
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 1</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              You shouldn't have to be at a job that doesn't challenge you to
              move forward.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig.mp4#t=50" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at GoGig
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 2</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Don't worry about having to do all the hard work required with
              searching for your next position to move up. Use GoGig effectively
              to make opportunities come to you.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig2.mp4#t=40" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at GoGig
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 3</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Because of the anonymous nature of GoGig, don't worry about your
              employers finding you. Your identity is completely anonymous.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig3.mp4#t=30" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Penguin.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at GoGig
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 5</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Don't have any path to promotion? That's fine, GoGig will help you
              get to the next level by bringing opportunities directly to you.
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig5.mp4#t=10.0" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard>

        <NewsFeedCard>
          <NewsFeedCardHeadingContainer>
            <NewsFeedCardImage
              src={require("images/icons/Avatar/Tiger.png")}
            />
            <NewsFeedCardDetailsContainer>
              <NewsFeedCardName>Alex Allen</NewsFeedCardName>
              <NewsFeedCardJob>
                Customer Success Manager at GoGig
              </NewsFeedCardJob>
            </NewsFeedCardDetailsContainer>
            <NewsFeedCardTime>Onboarding Pt. 4</NewsFeedCardTime>
          </NewsFeedCardHeadingContainer>
          <NewsFeedCardDescriptionContainer>
            <NewsFeedCardDescription>
              Our customer support is available all the time. If you have any
              issues with your profile, send our team an email at
              support@gogig.com
            </NewsFeedCardDescription>
          </NewsFeedCardDescriptionContainer>
          <NewsFeedVideoContainer>
            <video controls preload="metadata">
              <source type="video/mp4" src="/videos/gogig4.mp4#t=20" />
            </video>
          </NewsFeedVideoContainer>
        </NewsFeedCard> */}
      </div>
    );
  }
}

export default UserFeed;

const CompleteProfileCard = styled.div`
  background: white;
  padding: 20px;
  margin-top: 20px;
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const GoGigLogo = styled.img`
  width: 60px;
`;
const DownloadOnGoGig = styled.p`
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  font-weight: bold;
`;
const GetOnApple = styled.img`
  height: 40px;
`;
const GetOnGoogle = styled.img`
  height: 60px;
`;

const NewsFeedBlogContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px;
  border: 1px solid #f5f5f5;
  align-items: center;
`;
const NewsFeedBlogImg = styled.img`
  height: 100px;
  width: 100px;
  margin-right: 10px;
  object-fit: cover;
`;
const NewsFeedBlogMetaContainer = styled.div``;
const NewsFeedBlogHeadline = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;
const NewsFeedBlogDescription = styled.p`
  font-size: 12px;
`;

const CompleteProfileCardTitleContainer = styled.div`
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
`;
const CompleteProfileCardTitle = styled.p`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  margin-left: 10px;
`;
const CompleteProfileCardDescription = styled.p`
  margin: 0;
  font-size: 14px;
  margin-bottom: 10px;
`;
const CompleteProfileCardButton = styled.button``;

const NewsFeedCard = styled.div`
  background: white;
  margin-top: 20px;
  padding: 20px;
`;
const NewsFeedCardHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 10px;
`;

const NewsFeedCardImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: 10px;
`;

const NewsFeedCardDetailsContainer = styled.div`
  flex: 3;
`;
const NewsFeedCardName = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  margin: 0;
`;
const NewsFeedCardJob = styled.p`
  font-size: 12px;
  color: #999;
  margin: 0;
`;
const NewsFeedCardTime = styled.p`
  font-size: 12px;
  color: #999;
  margin: 0;
`;
const NewsFeedCardDescriptionContainer = styled.div``;
const NewsFeedCardDescription = styled.p`
  color: #999;
  margin: 0;
  font-size: 14px;
`;

const NewsFeedVideoContainer = styled.div`
  video {
    width: 100%;
  }
`;

const StyledSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f5f5f5;
  text-align: center;
  height: 100px;
  border-radius: 8px;
`;

const ProfilePhoto = styled.img`
  display: block;
  overflow: hidden;
  width: 100%;
  max-height: 500px;
`;
