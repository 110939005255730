import React, { Component } from "react";
import styled from "styled-components";

const StyledAvatarImg = styled.img`
  border: ${(props) => (props.selected ? "1px solid #002952" : "")};
  border-radius: ${(props) => (props.selected ? "50px" : "")};
  padding: 2px;
  :hover {
    border: ${(props) => (props.selection ? "1px solid #002952" : "")};
    border-radius: ${(props) => (props.selection ? "50px" : "")};
    cursor: ${(props) => (props.selection ? "pointer" : "")};
  }
`;

export class Avatar extends Component {
  render() {
    switch (this.props.character) {
      case "bear":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Bear.png")}
          />
        );
        break;
      case "cat":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Cat.png")}
          />
        );
      case "fox":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Fox.png")}
          />
        );
      case "cow":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Cow.png")}
          />
        );

      case "koala":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Koala.png")}
          />
        );
      case "tiger":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Tiger.png")}
          />
        );
      case "elephant":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Elephant.png")}
          />
        );
      case "penguin":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Penguin.png")}
          />
        );
      case "monkey":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Monkey.png")}
          />
        );
      case "rooster":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Rooster.png")}
          />
        );
      case "panda":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Panda.png")}
          />
        );
      case "moose":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Moose.png")}
          />
        );
      case "ram":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Ram.png")}
          />
        );
      case "wolf":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Wolf.png")}
          />
        );
      case "dog":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Dog.png")}
          />
        );
      case "lion":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Lion.png")}
          />
        );
      case "pig":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Pig.png")}
          />
        );
      case "man1":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Man 1.png")}
          />
        );
      case "man2":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Man 2.png")}
          />
        );
      case "man3":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Man 3.png")}
          />
        );
      case "man4":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Man 4.png")}
          />
        );
      case "man5":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Man 5.svg")}
          />
        );
      case "man6":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Man 6.png")}
          />
        );
      case "man7":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Man 7.png")}
          />
        );
      case "man8":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Man 8.png")}
          />
        );
      case "owl":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Owl.png")}
          />
        );
      case "raccoon":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Raccoon.png")}
          />
        );
      case "giraffe":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Giraffe.png")}
          />
        );
      case "rabbit":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Rabbit.png")}
          />
        );
      case "woman1":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Woman 1.png")}
          />
        );
      case "woman2":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Woman 2.png")}
          />
        );
      case "woman3":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Woman 3.png")}
          />
        );
      case "woman4":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Woman 4.png")}
          />
        );
      case "woman5":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Woman 5.png")}
          />
        );
      case "woman6":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Woman 6.png")}
          />
        );
      case "woman7":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Woman 7.png")}
          />
        );
      case "woman8":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Woman 8.png")}
          />
        );
      case "boy":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Boy.svg")}
          />
        );
      case "girl":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/Girl.svg")}
          />
        );
      case "neutral":
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            src={require("images/icons/Avatar/GenderNeutral.svg")}
          />
        );
      default:
        return (
          <StyledAvatarImg
            {...this.props}
            height={this.props.height}
            width={this.props.width}
            style={{
              background: "#D31528",
              borderRadius: 50,
            }}
          />
        );
    }
  }
}

Avatar.defaultProps = {
  character: "",
  height: 35,
  width: 35,
};

export const AvatarURL = (character) => {
  switch (character) {
    case "bear":
      return "images/icons/Avatar/Bear.png";
    case "cat":
      return "images/icons/Avatar/Cat.png";
    case "fox":
      return "images/icons/Avatar/Fox.png";
    case "cow":
      return "images/icons/Avatar/Cow.png";
    case "koala":
      return "images/icons/Avatar/Koala.png";
    case "tiger":
      return "images/icons/Avatar/Tiger.png";
    case "elephant":
      return "images/icons/Avatar/Elephant.png";
    case "penguin":
      return "images/icons/Avatar/Penguin.png";
    case "monkey":
      return "images/icons/Avatar/Monkey.png";
    case "rooster":
      return "images/icons/Avatar/Rooster.png";
    case "panda":
      return "images/icons/Avatar/Panda.png";
    case "moose":
      return "images/icons/Avatar/Moose.png";
    case "ram":
      return "images/icons/Avatar/Ram.png";
    case "wolf":
      return "images/icons/Avatar/Wolf.png";
    case "dog":
      return "images/icons/Avatar/Dog.png";
    case "lion":
      return "images/icons/Avatar/Lion.png";
    case "pig":
      return "images/icons/Avatar/Pig.png";
    case "man1":
      return "images/icons/Avatar/Man 1.png";
    case "owl":
      return "images/icons/Avatar/Owl.png";
    case "raccoon":
      return "images/icons/Avatar/Raccoon.png";
    case "woman2":
      return "images/icons/Avatar/Woman 2.png";
    case "giraffe":
      return "images/icons/Avatar/Giraffe.png";
    case "rabbit":
      return "images/icons/Avatar/Rabbit.png";
    case "woman1":
      return "images/icons/Avatar/Woman 1.png";
    case "man7":
      return "images/icons/Avatar/Man 7.png";
    case "man2":
      return "images/icons/Avatar/Man 2.png";
    case "man3":
      return "images/icons/Avatar/Man 3.png";
    case "man4":
      return "images/icons/Avatar/Man 4.png";
    case "man5":
      return "images/icons/Avatar/Man 5.svg";
    case "man6":
      return "images/icons/Avatar/Man 6.png";
    case "man8":
      return "images/icons/Avatar/Man 8.png";
    case "woman3":
      return "images/icons/Avatar/Woman 3.png";
    case "woman4":
      return "images/icons/Avatar/Woman 4.png";
    case "woman5":
      return "images/icons/Avatar/Woman 5.png";
    case "woman6":
      return "images/icons/Avatar/Woman 6.png";
    case "woman7":
      return "images/icons/Avatar/Woman 7.png";
    case "woman8":
      return "images/icons/Avatar/Woman 8.png";
    case "boy":
      return "images/icons/Avatar/Boy.svg";
    case "girl":
      return "images/icons/Avatar/Girl.svg";
    case "neutral":
      return "images/icons/Avatar/GenderNeutral.svg";
    default:
      return "";
  }
};
