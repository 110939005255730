import React, { Component } from "react";
import styled from "styled-components";
import { Avatar } from "./Avatars.stories.js";
import { Paragraph } from "./Typography.stories.js";
import CandidateSwitch from "../images/icons/switch/candidate.svg";
import RecruiterSwitch from "../images/icons/switch/recruiter.svg";
import { PrimaryLink } from "./Links.stories";
import { Link } from "react-router-dom";
import API from "../API";
import { Tooltip, Overlay, OverlayTrigger, Button } from "react-bootstrap";
import { MobileOnlyView, BrowserView } from "react-device-detect";

export default {
  title: "Profile Card",
};

class ProfileCard extends Component {
  state = {
    coverPhoto: "",
    recruiterEnabled: false,
    anonymousUsername: "",
    profileComplete: false,
    avatar: localStorage.getItem('avatarSlug')
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter[include]=avatar`
    ).then((response) => {
      this.setState({
        coverPhoto: response.data.coverPhoto,
        avatar: response.data.avatar.slug,
        anonymousUsername: response.data.anonymousUsername,
        profileComplete: response.data.profileComplete,
      });
      localStorage.setItem('avatarSlug', response.data.avatar.slug)
    });
    API.get(
      `/UserProfiles/${this.props.userToken.userId}/profileView/count`
    ).then((response) => {
      this.setState({
        profileViews: response.data.count,
      });
    });

    API.get(
      `/UserProfiles/${this.props.userToken.userId}/candidateMatches/count`
    ).then((response) => {
      this.setState({
        matches: response.data.count,
      });
    });
  }

  render() {
    return (
      <StyledProfileCardContainer>
        <StyledProfileCardCoverContainer>
          <MobileOnlyView>
            <StyledProfileCardImage
              style={{ height: null, width: "100%" }}
              src={
                this.state.coverPhoto ||
                require("images/covers/Coverphoto-Sidebar.png")
              }
            />
          </MobileOnlyView>
          <BrowserView>
            <StyledProfileCardImage
              src={
                this.state.coverPhoto ||
                require("images/covers/Coverphoto-Sidebar.png")
              }
            />
          </BrowserView>
        </StyledProfileCardCoverContainer>
        <BrowserView>
          <StyledProfileCardNameContainer>
            <StyledProfileCardNameWrapper>
              <div>
                <Avatar character={this.state.avatar} height={80} width={80} />
              </div>
              {!this.state.profileComplete ? (
                <div>
                  <Paragraph size="14px" className="mt-3">
                    <strong>TruPlay User {this.state.anonymousUsername}</strong>
                  </Paragraph>
                </div>
              ) : (
                <div
                  style={{
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Paragraph size="14px" className="mt-3 text-center m-0 p-0">
                    <strong>TruPlay User {this.state.anonymousUsername}</strong>
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: 12,
                    }}
                  >
                    <p className="text-center m-0 p-0 text-muted">
                      Your profile is 100% complete{" "}
                    </p>
                    <img
                      style={{ width: 18, marginLeft: 5 }}
                      src={require("../images/icons/medal.svg")}
                    />
                  </div>
                </div>
              )}
            </StyledProfileCardNameWrapper>
          </StyledProfileCardNameContainer>
        </BrowserView>
        <MobileOnlyView>
          <StyledProfileCardNameContainer>
            <StyledProfileCardNameWrapper>
              <div>
                <Avatar character={this.state.avatar} height={80} width={80} />
              </div>
              <div>
                <Paragraph size="14px" className="mt-3">
                  <strong>TruPlay User {this.state.anonymousUsername}</strong>
                </Paragraph>
              </div>
            </StyledProfileCardNameWrapper>
          </StyledProfileCardNameContainer>
        </MobileOnlyView>
        <StyledProfileCardAnalayticsContainer>
          <StyledProfileCardViewsContainer>
            <Paragraph size="14px" removeMargins>
              <strong>{this.state.profileViews}</strong>
            </Paragraph>
            <Paragraph size="14px" muted removeMargins>
              Views
            </Paragraph>
          </StyledProfileCardViewsContainer>
          <StyledProfileCardMatchesContainer>
            <Paragraph size="14px" removeMargins>
              <strong>{this.state.matches}</strong>
            </Paragraph>
            <Paragraph size="14px" muted removeMargins>
              Matches
            </Paragraph>
          </StyledProfileCardMatchesContainer>
        </StyledProfileCardAnalayticsContainer>
        <StyledProfileCardSwitchContainer>
          <Link to="/recruiter/dashboard" style={{ cursor: "pointer" }}>
            <img style={{ cursor: "pointer" }} src={CandidateSwitch} />
          </Link>
        </StyledProfileCardSwitchContainer>
        <MobileOnlyView>
          <StyledProfileCardLinkContainer style={{ cursor: "pointer" }}>
            <Link to="/logout" style={{ cursor: "pointer" }}>
              <PrimaryLink size="14px" style={{ cursor: "pointer" }}>
                Logout
              </PrimaryLink>
            </Link>
          </StyledProfileCardLinkContainer>
        </MobileOnlyView>
        <BrowserView>
          <StyledProfileCardLinkContainer>
            <Link to="/profile">
              <PrimaryLink size="14px">View My Profile</PrimaryLink>
            </Link>
          </StyledProfileCardLinkContainer>
        </BrowserView>
        {/* <ButtonsContainer>
          <a
            className="w-100"
            href={`https://www.gogig.com/relopro-relocation-services?ref=gogig&user=${this.props.userToken.userId}`}
            target="_blank"
          >
            <Button size="sm" block disabled={!this.state.profileComplete}>
              Relocation Services
            </Button>
          </a>
        </ButtonsContainer>
        <ButtonsContainer>
          <Link to="/jobs" className="w-100">
            <Button size="sm" block disabled={!this.state.profileComplete}>
              TruPlay Careers Hiring Partners
            </Button>
          </Link>
        </ButtonsContainer> */}
        <BrowserView>
          <div
            style={{
              padding: '20px',
              borderTop: '1px solid #efefef',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <a
              style={{
                padding: '0 5px',
              }}
              href="https://www.linkedin.com/company/truplay/"
              target="_blank"
            >
              <img
                src="/icons/linkedin-skyblue-icon.svg"
                alt="LinkedIn Logos"
              />
            </a>
            <a
              style={{
                padding: '0 5px',
              }}
              href="https://instagram.com/truplaygames?igshid=NTdlMDg3MTY="
              target="_blank"
            >
              <img
                src="/icons/instagram-skyblue-icon.svg"
                alt="Instagram Logos"
              />
            </a>
            <a
              style={{
                padding: '0 5px',
              }}
              href="https://www.facebook.com/truplaygames/"
              target="_blank"
            >
              <img
                src="/icons/facebook-skyblue-icon.svg"
                alt="Facebook Logos"
              />
            </a>
            <a
              style={{
                padding: '0 5px',
              }}
              href="https://twitter.com/TruplayGames/"
              target="_blank"
            >
              <img
                src="/icons/twitter-skyblue-icon.svg"
                alt="Twitter Logos"
              />
            </a>
          </div>
        </BrowserView>
      </StyledProfileCardContainer>
    );
  }
}

class RecruiterProfileCard extends Component {
  state = {
    coverPhoto: "",
    anonymousUsername: "",
    avatar: localStorage.getItem('avatarSlug')
  };

  componentDidMount() {
    API.get(
      `/UserProfiles/${this.props.userToken.userId}?filter[include]=avatar`
    ).then((response) => {
      this.setState({
        coverPhoto: response.data.coverPhoto,
        avatar: response.data.avatar.slug,
        anonymousUsername: response.data.anonymousUsername,
      });
    });

    API.get(
      `/UserProfiles/${this.props.userToken.userId}/profileView/count`
    ).then((response) => {
      this.setState({
        profileViews: response.data.count,
      });
    });

    API.get(
      `/UserProfiles/${this.props.userToken.userId}/recruiterMatches/count`
    ).then((response) => {
      this.setState({
        matches: response.data.count,
      });
    });
  }
  render() {
    return (
      <StyledProfileCardContainer>
        <StyledProfileCardCoverContainer>
          <StyledProfileCardImage
            src={
              this.state.coverPhoto ||
              require("images/covers/Coverphoto-Sidebar.png")
            }
          />
        </StyledProfileCardCoverContainer>
        <StyledProfileCardNameContainer>
          <StyledProfileCardNameWrapper>
            <div>
              <Avatar character={this.state.avatar} height={80} width={80} />
            </div>
            <div>
              <Paragraph size="14px" className="mt-3">
                <strong>TruPlay User {this.state.anonymousUsername}</strong>
              </Paragraph>
            </div>
          </StyledProfileCardNameWrapper>
        </StyledProfileCardNameContainer>
        <StyledProfileCardAnalayticsContainer>
          <StyledProfileCardViewsContainer>
            <Paragraph size="14px" removeMargins>
              <strong>{this.state.profileViews}</strong>
            </Paragraph>
            <Paragraph size="14px" muted removeMargins>
              Messsages
            </Paragraph>
          </StyledProfileCardViewsContainer>
          <StyledProfileCardMatchesContainer>
            <Paragraph size="14px" removeMargins>
              <strong>{this.state.matches}</strong>
            </Paragraph>
            <Paragraph size="14px" muted removeMargins>
              Matches
            </Paragraph>
          </StyledProfileCardMatchesContainer>
        </StyledProfileCardAnalayticsContainer>
        <StyledProfileCardSwitchContainer>
          <Link to="/getstarted">
            <img src={RecruiterSwitch} />
          </Link>
        </StyledProfileCardSwitchContainer>
        <BrowserView>
          <div
            style={{
              padding: '20px',
              borderTop: '1px solid #efefef',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <a
              style={{
                padding: '0 5px',
              }}
              href="https://www.linkedin.com/company/truplay/"
              target="_blank"
            >
              <img
                src="/icons/linkedin-skyblue-icon.svg"
                alt="LinkedIn Logos"
              />
            </a>
            <a
              style={{
                padding: '0 5px',
              }}
              href="https://instagram.com/truplaygames?igshid=NTdlMDg3MTY="
              target="_blank"
            >
              <img
                src="/icons/instagram-skyblue-icon.svg"
                alt="Instagram Logos"
              />
            </a>
            <a
              style={{
                padding: '0 5px',
              }}
              href="https://www.facebook.com/truplaygames/"
              target="_blank"
            >
              <img
                src="/icons/facebook-skyblue-icon.svg"
                alt="Facebook Logos"
              />
            </a>
            <a
              style={{
                padding: '0 5px',
              }}
              href="https://twitter.com/TruplayGames/"
              target="_blank"
            >
              <img
                src="/icons/twitter-skyblue-icon.svg"
                alt="Twitter Logos"
              />
            </a>
          </div>
        </BrowserView>
      </StyledProfileCardContainer>
    );
  }
}

export { ProfileCard, RecruiterProfileCard };

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  border-top: 1px solid #efefef;
  padding: 20px;
`;

const StyledProfileCardContainer = styled.div`
  background: white;
  border: 1px solid #efefef;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;
const StyledProfileCardCoverContainer = styled.div`
  background: #f5f5f5;
  height: 100px;
  width: 100%;
  overflow: hidden;
`;

const StyledProfileCardNameContainer = styled.div`
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const StyledProfileCardAnalayticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #efefef;
  align-items: center;
`;
const StyledProfileCardSwitchContainer = styled.div`
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: center;
  padding: 20px;
  img {
    max-width: 100%;
  }
`;
const StyledProfileCardLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const StyledProfileCardViewsContainer = styled.div`
  border-right: 0.5px solid #efefef;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;
const StyledProfileCardMatchesContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  border-left: 0.5px solid #efefef;
  padding: 20px;
`;

const StyledProfileCardNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 35px;
  height: 90px;
`;

const StyledProfileCardImage = styled.img`
  height: 100%;
  width: 100%;
`;
